.f-btn-dropdown {
  font-size: 14px;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 60px;
  height: 38px;
  position: relative;
  overflow: hidden;

  &:before {
    .icon-arrow-bottom2(#73808d);

    content: '';

    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(50%, -50%);
    z-index: 1;

    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &:after {
    content: '';

    display: block;
    position: absolute;
    width: 40px;
    height: 100%;
    top: 0;
    right: 0;

    background: #c6cfd3;

    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  &:hover:after {
    background: inherit !important;
  }

  &.f-btn-primary {
    &:before {
      .icon-arrow-bottom2(#fff);
    }
    &:after {
      background: #3553c1;
    }
  }

  & + .dropdown-menu {
    &:before {
      right: 9px !important;
    }
  }
}
