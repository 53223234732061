@import 'Style/breakpoints';

:root {
  --foquz-dialog-animation-duration: 250ms;
}

@keyframes dialog-appears {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes dialog-disappears {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}

.foquz-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--foquz-dialog-z-index);

  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;

  outline: 0;

  &.shown {
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    opacity: 1;
    visibility: visible;
  }

  &.showing {
    display: block;
    visibility: hidden;
    opacity: 0;
    animation: dialog-appears var(--foquz-dialog-animation-duration) ease-in-out forwards;
  }

  &.hiding {
    display: block;
    animation: dialog-disappears var(--foquz-dialog-animation-duration) ease-in-out forwards;
  }

  &.hidden {
    display: none;
  }

  &__mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    z-index: 1;

    [data-dialog-index='0'] & {
      background: rgba(0, 0, 0, 0.65);
    }
  }

  // определяет ширину модального окна
  &__container {
    position: relative;
    z-index: 2;

    max-width: 100%;
    min-height: calc(100% - 3.5rem);
    margin: 1.75rem auto;

    width: 460px; // дефолтная ширина

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none; // чтобы не перекрывал маску
  }

  &--lg {
    .foquz-dialog__container {
      width: 700px;
    }
  }

  // блок контента
  &__content {
    position: relative;
    background: white;
    width: 100%; // равна ширине контейнера
    display: flex;
    flex-direction: column;
    border-radius: 9px;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.18);
    pointer-events: auto;
  }

  &__body {
    padding: 35px 30px;
    .dialog-link {
      color: #3f65f1;
    }

    b {
      font-weight: bold;
    }

    .foquz-dialog__header + & {
      padding-top: 8px;
    }

    .full-width {
      margin-left: -30px;
      margin-right: -30px;
    }

    p {
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      font-size: 14px;
      font-weight: 500;
      color: #3F65F1;
    
      li {
        display: flex;
        margin-bottom: 12px;
        line-height: 1.3;
      }
  
      &.list--inner {
        li {
          display: block;
  
          &:before {
            display: inline-block;
            vertical-align: top;
            margin-top: calc(1.3em - 15px);
          }
        }
        &.list--left-margin {
          li {
            display: flex;
            line-height: 24px;
            margin-bottom: 15px;
            &:before {
              margin-top: 8px;
              margin-right: 8px;
              width: 6px;
              height: 6px;
            }
          }
        }
        &.list--left-margin-2 {
          margin-top: -16px;
          li {
            display: flex;
            line-height: 24px;
            margin-bottom: 15px;
            &:before {
              margin-top: 10px;
              margin-right: 8px;
              width: 6px;
              height: 6px;
            }
          }
        }
      }
    }
  }

  &__footer {
    position: relative;
    min-height: 75px;
    background: #fbfbfb;
    padding: 20px 30px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .f-btn + .f-btn {
      margin-left: 10px;
    }

    .fc-btn-b {
      height: 38px;
    }
  }


  .only-mobile({
    &:not(.sidesheet):not(.mobile-sm) {
      .foquz-dialog {
        &__container {
          margin: 0;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        &__header {
          padding-left: 15px;
          padding-right: 50px;
          padding-top: 30px;
        }

        &-header--empty {
          padding-bottom: 12px;
        }

        &__close {
          top: 25px;
          right: 25px;
        }

        &__content {
          flex-grow: 1;
          border-radius: 0;
          height: 100%;
          overflow: hidden;
        }
        &__body {
          padding-left: 15px;
          padding-right: 15px;
        }
        &__footer {
          background: none;
          padding-left: 5px;
          padding-right: 5px;

          padding-top: 0;
          min-height: 0;
        }
        &__actions {
          .f-btn {
            flex-grow: 1;
            flex-basis: 50%;
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
    }

    &__scroll {
      .os-scrollbar-vertical {
        display: none;
      }
    }

  });
}

body {
  &.no-scroll {
    height: 100%;
    overflow: hidden;
  }
}
