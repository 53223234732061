@import 'Style/colors';

.fc-switch {
  display: block;

  &__container {
    display: flex;
    align-items: center;
  }

  &__hint {
    flex-shrink: 0;
  }

  label {
    display: flex;
    cursor: pointer;
  }

  &__input {
    display: block !important;
    opacity: 0;
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }

  &__box {
    display: block;
    width: 50px;
    height: 26px;
    margin-right: 10px;
    border-radius: 50px;
    position: relative;
    flex-shrink: 0;
    background: #dadfe3;

    transition: color 250ms ease-in-out;
  }

  &__marker {
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    top: 2px;
    left: 2px;
    background: white;
    border-radius: 50%;

    transition: transform 250ms ease-in-out;
  }

  &__label {
    color: @f-color-service;
    min-height: 26px;
    display: flex;
    align-items: center;
  }

  &__input:checked ~ &__box {
    background-color: @f-color-primary;
    .fc-switch__marker {
      transform: translateX(24px);
    }
  }

  &__input:checked ~ &__label {
    color: @f-color-text;
  }

  &--disabled {
    opacity: 0.6;

    label {
      cursor: default;
    }
  }
}
