@scroll-indicator-width: 25px;

.scroll-indicator(@position) {
  content: '';
  position: absolute;
  z-index: 2;
  opacity: 1;
  pointer-events: none;

  & when (@position = 'left') {
    top: 0;
    bottom: 0;
    left: 0;
    width: @scroll-indicator-width;
    background: linear-gradient(to right, white, transparent);
  }

  & when (@position = 'right') {
    top: 0;
    bottom: 0;
    right: 0;
    width: @scroll-indicator-width;
    background: linear-gradient(to left, white, transparent);
  }

  & when (@position = 'top') {
    left: 0;
    right: 0;
    top: 0;
    height: @scroll-indicator-width;
    background: linear-gradient(to bottom, white, transparent);
  }

  & when (@position = 'bottom') {
    left: 0;
    right: 0;
    bottom: 0;
    height: @scroll-indicator-width;
    background: linear-gradient(to top, white, transparent);
  }
}

.scroll-indicator-inactive() {
  opacity: 0;
}

.native-scrollbar.native-scrollbar--indicator {
  &:not(.os-host-scrollbar-horizontal-hidden).native-scrollbar--has-x {
    &:before {
      .scroll-indicator('left');
    }
    &:after {
      .scroll-indicator('right');
    }

    &.native-scrollbar--x-start {
      &:before {
        .scroll-indicator-inactive()
      }
    }
    &.native-scrollbar--x-end {
      &:after {
        .scroll-indicator-inactive()
      }
    }
  }

  &:not(.os-host-scrollbar-vertical-hidden).native-scrollbar--has-y {
    & > .os-padding {

      &:before {
        .scroll-indicator('top');
      }
      &:after {
        .scroll-indicator('bottom');
      }
    }
    &.native-scrollbar--y-start {
      & > .os-padding {
        &:before {
          .scroll-indicator-inactive()
        }
      }
    }
    &.native-scrollbar--y-end {
      & > .os-padding {
        &:after {
          .scroll-indicator-inactive()
        }
      }
    }
  }
}

.native-scrollbar.os-theme-dark {
  .os-scrollbar {
    .os-scrollbar-track {
      background-color: #E8EDEE;
    }

    .os-scrollbar-handle {
      background-color: #8E99A3;
      border-radius: 4px;
    }

    &-vertical {
      width: 8px;
    }

    &-horizontal {
      height: 8px;
    }
  }

}
