:root {
  --fc-star-rating-stars-count: 5;
  --fc-star-rating-spacing: 16px;
  --fc-star-rating-star-size: 25px;
  --fc-star-rating-bg-color: #fff;
}

.fc-star-rating {
  width: calc(
    var(--fc-star-rating-stars-count) * var(--fc-star-rating-star-size) +
      var(--fc-star-rating-spacing) * (var(--fc-star-rating-stars-count) - 1)
  );
  max-width: 100%;

  &__wrapper {
    display: flex;
    justify-content: space-between !important;
  }

  &__item {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 2px;
    }

    svg {
      width: var(--fc-star-rating-star-size);
      height: var(--fc-star-rating-star-size);
    }
  }

  &__label {
    font-size: 13px!important;
    font-weight: 700;
    margin-top: 15px;
    overflow: hidden;
    width: 100px;
    text-align: center;

    &:empty {
      margin-top: 0;
    }

    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 10px;
      background: linear-gradient(
        to left,
        var(--fc-star-rating-bg-color),
        transparent
      );
    }
  }

  &__index {
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &[data-size="sm"] {
    --fc-star-rating-spacing: 11px;
    --fc-star-rating-star-size: 18px;
  }

  &[data-size="md"] {
    --fc-star-rating-spacing: 18px;
    --fc-star-rating-star-size: 32px;
  }

  &[data-size="lg"] {
    --fc-star-rating-spacing: 22px;
    --fc-star-rating-star-size: 48px;
  }

  &--labels {
    width: auto;
  }

  @media screen and (max-width: 767.98px) {
    &[data-size="sm"],
    &[data-size="md"] {
      --fc-star-rating-spacing: 18px;
      --fc-star-rating-star-size: 27px;
    }

    &__label {
      width: 54px;
      font-size: 10px!important;
    }

    &__index {
      font-size: 10px!important;
    }
  }
}
