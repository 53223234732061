@import "@/less/vars/colors";

.fc-button-group {
  display: inline-flex;
  border: 1px solid @border-color;
  border-radius: 4px;

  &--block {
    width: 100%;
  }

  .fc-btn {
    .fc-btn-b {
      width: 100%;
      background: #f1f5f6;
      transition: background 250ms, color 250ms;
    }

    &:not(:last-child) {
      border-right: 1px solid @border-color;
      .fc-btn-b {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(:first-child) {
      .fc-btn-b {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &-item {
    flex-grow: 1;
    flex-basis: 100%;

    &.active {
      .fc-btn-b {
        background: white;
        color: black;

        &__icon {
          color: var(--f-color-primary);
        }
      }
    }
  }

  &[data-size="xl"] {
    .fc-btn-b {
      font-size: 16px;
    }
  }
}
