@import 'Style/colors';
.fc-toast {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 5001;
  background: #ABEFB7;
  box-shadow: 0px 5px 20px rgba(115, 128, 141, 0.4);
  border-radius: 9px;
}

.fc-toast--warning {
  top: 50%;
  left: 50%;
  right: unset;
  bottom: unset;
  transform: translate(-50%, -50%);
  background: #FFDDDD;
}

.fc-toast__message {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2E2F31;
  margin: 33px 30px 35px;
}

.fc-toast__close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}
