@import 'Style/colors';
.fc-success {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #ABEFB7;
  color: #37A74A;

  border-radius: inherit;

  
}
