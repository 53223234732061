.mobile-info-dialog {

  p {
    margin-top: 12px;
    margin-bottom: 12px;
    line-height: 1.4;
  }

  ul {
    margin-bottom: 14px;
  }

  .foquz-dialog__container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .foquz-dialog__body {
    padding-bottom: 0;
  }

  .foquz-dialog__footer {
    background: transparent;
    padding-top: 0px;
    min-height: 0;
  }
  .foquz-dialog__actions {
    justify-content: center;
  }

  ul {
    li {
      margin-bottom: 6px;

      .svg-icon {
        width: 16px;
        height: 10px;
        margin-right: 10px;
      }
    }
  }
}
