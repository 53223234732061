@import "Style/colors";
.fc-select-with-except {
  display: block;

  &__wrapper {
    position: relative;
  }

  &__control {
    .fc-select-field {
      padding-right: 50px;
    }
  }

  &__button {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
    
    svg {
      color: #a6b1bc;
    }

    [data-selected] {
      svg {
        color: @f-color-primary;
      }
    }
  }
}
