.foquz-password-field {
  display: block;

  .foquz-password-field__help {
    width: 310px;
  }
  
  .form-control {
    background-position: calc(100% - 50px) center !important;
  }
}

