@import 'Style/colors';
@import 'Style/breakpoints';

.fc-input {
  display: block;
  font-size: 16px;

  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding: 0 12px 0 15px;
    height: 48px;
    max-width: 100%;
  }

  &__field,
  &__icon,
  &__icons {
    position: relative;
    z-index: 1;
  }

  &__field {
    border: none;
    background-color: transparent;
    height: 48px;
    flex-grow: 1;
    max-width: 100%;
    text-overflow: ellipsis;
    text-align: inherit;

    &:focus {
      outline: none;
      border: none;
    }

    &::placeholder {
      color: @f-color-service;
    }
  }

  &__icon {
    margin-right: 14px;
  }

  &__icons {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding-right: 12px;
    display: flex;
    align-items: center;
  }

  &__count {
    font-size: 11px;
    color: @f-color-text-inactive;
  }

  &__clear {
    &.fc-btn {
      display: flex;
      align-items: center;
    }
  }

  &__clear ~ .fc-input__count {
    display: none;
  }

  &__view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    border: 1px solid @f-color-border--dark;
    border-radius: inherit;
    pointer-events: none;
    font: inherit;
    color: @f-color-text;
    z-index: 0;
  }

  &__field:focus ~ .fc-input__view {
    border-color: @f-color-primary;
    box-shadow: 0 0 5px rgba(63, 101, 241, 0.5);
  }

  &--invalid {
    .fc-input__view {
      border-color: @f-color-danger;
      box-shadow: none;
    }

    .fc-input__field:focus ~ .fc-input__view {
      border-color: @f-color-danger;
      box-shadow: none;
    }
  }

  .fc-input__append-icon {
    margin-left: 10px;
  }

  .fc-input__valid {
    display: none;
    margin-left: 10px;
  }

  &--valid {
    .fc-input__view {
      border-color: @f-color-success;
      box-shadow: none;
    }
    .fc-input__valid {
      display: block;
    }
    .fc-input__count {
      display: none;
    }
  }

  &--disabled {
    .fc-input__field {
      opacity: 0.5;
    }

    .fc-input__icon {
      opacity: 0.5;
    }

    .fc-input__view {
      background-color: #f2f5f6;
    }
  }

  &--counter {
    .fc-input__field {
      padding-right: 35px;
    }
  }

  &--clearable {
    .fc-input__field {
      padding-right: 20px;
    }
  }

  &--valid.fc-input--clearable {
    .fc-input__field {
      padding-right: 40px;
    }
  }

  &--append-icon {

  }

  .only-mobile({
    // font-size: 14px;
    .fc-input__icon {
      margin-right: 8px;
    }
  })
}

.fc-input-aligner {
  display: flex;
  align-items: center;
  min-height: 48px;
}
