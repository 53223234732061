@import 'Style/typography';
@import 'Style/colors';

:root {
  --foquz-check-size: 24px;
  --foquz-check-font-size: 14px;
}

.check-input {
  display: block;
  &__wrapper {
    display: flex;
    min-height: var(--foquz-check-size);
    cursor: pointer;
  }
  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: block;
  }

  &__icon {
    position: relative;
    width: var(--foquz-check-size);
    height: var(--foquz-check-size);
    margin-right: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: @f-color-border--dark;
    flex-shrink: 0;
    transition: color 250ms ease-in-out;
    background: white;
  }

  &.single {
    .check-input__wrapper {
      display: inline-flex;
    }
    .check-input__icon {
      margin-right: 0;
    }
    .check-input__label {
      display: none;
    }
  }

  &__label {
    font-size: var(--foquz-check-font-size);
    line-height: 1;
    padding-top: calc(
      var(--foquz-check-size) / 2 - var(--foquz-check-font-size) / 2 + 1px
    );
    color: @f-color-service;
    transition: color 250ms ease-in-out;
  }

  // FOCUS
  input:focus ~ .check-input__icon {
    color: @f-color-primary;
    border-color: @f-color-primary;
    box-shadow: 0px 0px 5px rgba(63, 101, 241, 0.5);
  }
  input:focus ~ .check-input__label {
    color: @f-color-text;
  }

  // CHECKED
  input:checked ~ .check-input__icon {
    color: @f-color-primary;
  }
  input:checked ~ .check-input__label {
    color: @f-color-text;
  }

  // DISABLED
  input[disabled] ~ .check-input__icon,
  input[disabled] ~ .check-input__label {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.check-input.sm {
  --foquz-check-size: 18px;
  --foquz-check-font-size: 12px;
}
