.f-btn {
  &-sm {
    height: 32px;
    font-weight: normal;
  }

  &-lg {
    font-size: 14px;
    font-weight: 500;
    height: 38px;

    &:not(.f-btn-text) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &-xl {
    font-size: 16px;
  }

  &--base {
    min-width: 128px;
  }

  &--block {
    width: 100%;
  }

}
