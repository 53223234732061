.fc-btn {
  &-b {
    &--pill {
      border-radius: 60px;
    }

    &--circle {
      height: 38px;
      width: 38px;
      padding: 0;
      border-radius: 50%;

      &.fc-btn-b--sm {
        width: 32px;
        height: 32px;
      }
    }

    &--square {
      width: 38px;
      padding: 0;
    }
  }
}
