.foquz-video-preview {
  width: 105px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;

  &__container {
    width: 100%;
    padding-top: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .video__mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .video-mask-owerlay {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .icon-video-play-button(@color) {
    @svg-content: escape('
      <svg width="72" height="50" viewBox="0 0 72 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M67.5435 3.72999C69.6729 5.88727 70.3688 10.7864 70.3688 10.7864C70.3688 10.7864 71.0786 16.5344 71.0786 22.2895V27.6827C71.0786 33.4308 70.3688 39.1858 70.3688 39.1858C70.3688 39.1858 69.6729 44.0849 67.5435 46.2422C65.1105 48.7942 62.4121 49.058 60.8837 49.2074C60.7159 49.2238 60.5622 49.2389 60.4245 49.2554C50.4732 49.9791 35.5393 50 35.5393 50C35.5393 50 17.0564 49.826 11.3709 49.2832C11.0993 49.2318 10.7694 49.1918 10.3956 49.1466C8.59558 48.9287 5.77669 48.5874 3.53514 46.2422C1.40571 44.0849 0.709812 39.1858 0.709812 39.1858C0.709812 39.1858 0 33.4377 0 27.6827V22.2895C0 16.5344 0.709812 10.7864 0.709812 10.7864C0.709812 10.7864 1.40571 5.88727 3.53514 3.72999C5.96678 1.17928 8.66933 0.914409 10.1942 0.764964C10.3625 0.748466 10.5165 0.733375 10.6541 0.716771C20.6054 0 35.5254 0 35.5254 0H35.5532C35.5532 0 50.4732 0 60.4245 0.716771C60.5622 0.733375 60.7161 0.748466 60.8845 0.764964C62.4093 0.914409 65.1119 1.17928 67.5435 3.72999ZM30.7597 16.4092L44.2391 23.3821C45.5474 24.064 45.5474 25.929 44.2391 26.618L30.7597 33.5978C29.5488 34.2171 28.1083 33.3403 28.1083 31.9833V18.0237C28.1083 16.6597 29.5488 15.7829 30.7597 16.4092Z" fill="@{color}"/>
      </svg>
    ');
    width: 72px;
    height: 50px;
    background-image: url("data:image/svg+xml,@{svg-content}");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .video__play-button {
    .icon-video-play-button(#FFF);
    position: absolute;
    top: calc((100% - 50px) / 2);
    left: calc((100% - 72px) / 2);
    opacity: 0.7;
  }

  &__description {
    margin-top: 5px;
    font-size: 12px !important;
    line-height: 14px; 
    width: 100%;
  }
}
