@import 'Style/colors';

.fc-month-calendar {
  display: inline-block;

  &__wrapper {
    display: inline-block;
  }

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: 10px;
    border-bottom: 1px solid #cfd8dc;
  }

  &__month-select {
    width: 158px;
    margin-right: 10px;
  }

  &__year-select {
    width: 68px;
  }

  &__month-select,
  &__year-select {
    .fc-select-field {
      min-height: 24px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 13px;

      &__arrow {
        margin-left: 5px;
        margin-right: -5px;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  &__prev,
  &__next {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;

    cursor: pointer;
  }

  &__prev {
    margin-left: 8px;
  }

  &__next {
    margin-right: 8px;
  }

  &__month {
    flex-grow: 1;
    text-align: center;
    font-size: 16px;

    .month-name {
      text-transform: capitalize;
    }
  }

  &__week {
    display: flex;
    justify-content: center;
  }
  &__day {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;
    font-size: 14px;
    line-height: 1;
    cursor: pointer;



    &.inactive {
      opacity: 0.5;
      cursor: default;
    }
  }

  // HOVER
  &__day {
    &:not(.inactive) {
      &:hover {
        background: #eee;
      }
    }
  }
  &--range {
    .fc-month-calendar__day {
      &:not(.inactive) {
        &:hover {
          background-color: #d9e0fc;
        }
      }
    }
  }

  // DAY STATES
  .fc-month-calendar__day {
    &:not(.inactive) {
      &.selected {
        background: @f-color-primary;
        color: white;
      }

      &.in-range {
        background: #d9e0fc;
        border-radius: 0;
      }

      &.from-range,
      &.to-range {
        background: @f-color-primary;
        color: white;
      }

      &.from-range {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &.to-range {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
