@import "Style/colors";
@import "Style/typography";

@step: 4px;

.table td,
.table th {
  border-color: @f-color-table-border;
}

.f-table {
  td,
  th {
    &[valign="middle"] {
      vertical-align: middle;
    }
    &[valign="top"] {
      vertical-align: top;
    }
    &[align="center"] {
      text-align: center;
    }
    &[align="right"] {
      text-align: right;
    }
    padding-left: 10px;
    padding-right: 10px;
    .breacked {
      word-break: break-all;
    }
  }

  thead {
    th {
      vertical-align: top;
      padding-top: 0;
      padding-bottom: 12px;
    }
  }

  &--searchable {
    thead {
      th {
        padding-bottom: 4px;
      }
    }
  }

  th {
    font-size: @f-fs-table-head;
    font-weight: bold;
    border-top: none;
    vertical-align: top;

    .f-check {
      margin-top: -5px;
    }

    .f-icon--sorting-order {
      margin-top: 2px;
    }
  }

  td {
    font-size: @f-fs-table-cell;
    font-weight: normal;
    padding-top: 20px;
    padding-bottom: 20px;

    &.sm {
      font-size: 12px;
    }
    &.semibold {
      font-weight: 500;
    }
  }

  &-dense {
    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  &-sm {
    td {
      font-size: @f-fs-table-head;
      font-weight: 500;
    }
  }

  thead tr {
    // border-bottom: 1px solid @f-color-table-border;
  }

  tbody tr:last-child {
    td {
      border-bottom: 1px solid @f-color-table-border;
    }
  }

  &--fixed {
    table-layout: fixed;
  }

  &__row {
    &--selected {
      background: #f1f4ff;
    }
    &--error {
      background: #ffecec;
    }
  }

  &--outer {
    margin-left: -7px;
    margin-right: 7px;
    width: calc(100% + 14px);
  }

  &__outer-element-head-cell,
  th.f-table__outer-cell {
    width: 7px;
    min-width: 7px;
    padding: 0 !important;

    border: none !important;
  }

  &__outer-element-cell,
  &__outer-cell {
    padding: 0 !important;

    border: none !important;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &__cell {
    &-name {
      display: flex;
    }
  }

  &__filter {
    margin-left: 4px;
  }

  &__search {
    border: none;
    outline: none;
    box-shadow: none;
    width: 100%;
    margin-top: 4px;
  }

  &--sticky {
    thead {
      th {
        position: sticky;
        top: -1px;
        background: white;
        z-index: 10;
        padding-top: 10px;
      }

      &.fixed {
        z-index: 8;
        &.fixed {
          th {
            &:after {
              content: "";
              position: absolute;
              bottom: -22px;
              left: 0;
              right: 0;
              height: 22px;
              background: linear-gradient(
                to top,
                rgba(115, 128, 141, 0) 0%,
                rgba(115, 128, 141, 0.15) 100%
              );
            }

            &:first-child {
              &:after {
                left: -20px;
              }
            }

            &:last-child {
              &:after {
                right: -20px;
              }
            }
          }
        }
      }
    }
  }
}

.scroll-container {
  .f-table {
    &__cell-name {
      white-space: nowrap;
    }
  }
}

.fixed-table {
  min-width: 100%;

  th,
  td {
    padding-top: 2 * @step;
    padding-bottom: 2 * @step;
    padding-left: 15px;
    padding-right: 10px;

    font-size: 12px;

    border-bottom: 1px solid @f-color-border;
  }

  th {
    position: sticky;
    left: 0;
    z-index: 20;

    font-weight: 700;

    background: white;
    box-shadow: 6px 0 10px -1px rgba(236, 239, 241, 0.65);
  }

  td {
    border-left: 1px solid @f-color-border;
  }
}

.f-icon--sorting-order--default svg {
  color: #a6b1bc !important;
}

.f-table__search::placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #a6b1bc;
}
