@import "Style/colors";
@import "Style/breakpoints";

.publish-poll {
  .publish-btn {
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    box-shadow: none;
    border: none;

    background: @f-color-danger;
    color: white;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    border-radius: 4px;
    white-space: nowrap;

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .only-mobile({
    .publish-btn {
      width: 38px;
      padding: 0;
    }
  });
}

.watcher-btn {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  gap: 10px;
  border: 0;
  outline: none;

  svg {
    color: inherit;
  }

  &.watcher-btn__started {
    color: #16ceb9;
  }

  &.watcher-btn__stoped {
    color: #f96261;
  }
}
