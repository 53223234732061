@import 'Style/colors';

select2 {
  display: block;

  select {
    width: 100%;
  }

  .foquz-select2__wrapper {
    .select2 {
      width: 100% !important;
    }
  }
}

.foquz-select2__wrapper {
  .select2 {
    width: 100% !important;
  }
}

.select2 {
  &-results__option {
    .divider {
      position: relative;
      display: block;
      width: 100%;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        height: 0;
        border-bottom: 1px solid @f-color-border;
      }
    }
  }
}

.select2.select2-container--form-control.select2-container--disabled.f-select2--mode_view {
  .select2-selection__rendered {
    color: @f-color-text!important;
  }

  .select2-selection {
    background: transparent !important;

    &__arrow {
      display: none;
    }
  }

  .select2-selection {
    .select2-selection__placeholder {
      color: @f-color-text!important;
      font-weight: 500;
    }
    .select2-selection__rendered {
      .select2-search--inline {
        margin-top: 0 !important;

        input {
          color: @f-color-text;
          font-weight: 400;
        }
        input::placeholder {
          opacity: 1;
          color: @f-color-service;
          font-weight: 400;
        }
      }
    }
  }
}

.select2-dropdown.dense-form-group__dropdown {
  max-width: 300px;
}

.foquz-select2__except {
  flex-shrink: 0;
  white-space: nowrap;
  transform: translateY(-2px);

  .f-check-label {
    padding-top: 2px !important;
  }
}

.dense-form-group {
  .foquz-select2__wrapper {
    .select2-container--form-control
      .select2-selection--multiple.select2-selection
      .select2-selection__rendered {
      margin-top: 0;
    }

    .select2-container--form-control
      .select2-selection--multiple
      .select2-selection__choice__remove {
      margin-top: 2px;
    }

    .select2-container--default
      .select2-selection--multiple
      .select2-selection__choice,
    .select2-container--default
      .select2-selection--single
      .select2-selection__rendered {
      margin-top: -2px;
    }
    .select2-container .select2-search--inline .select2-search__field {
      margin-top: 0;
    }
  }
}

.select2 .select2-selection {
  max-width: 100%;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
