.foquz-slider {
  display: flex;
  align-items: center;

  height: 24px;

  position: relative;

  &__slider {
    flex-grow: 1;
    min-width: 250px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .ui-slider {
    .foquz-slider__handle {
      width: 24px;
      height: 24px;
      top: calc(6px / 2 - 24px / 2);
    }
  }

  &__value {
    flex-grow: 0;
    font-size: 16px;
    font-weight: 700;
    margin-left: 10px;
  }

  &.sm {
    .foquz-slider {
      &__slider {
        min-width: 120px;
      }
      &__handle {
        width: 17px;
        height: 17px;
        top: calc(3px / 2 - 17px / 2);
      }
      &__value {
        margin-left: 5px;
        font-size: 14px;
      }
    }

    .ui-slider {
      height: 3px;
    }
  }
}
