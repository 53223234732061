@import 'Style/colors';

.interactive-table {
  display: block;

  &__not-found {
    text-align: center;
    color: @f-color-service;
    padding: 10px;
  }

  &__empty {
    color: @f-color-service;
    padding: 10px 0;
  }

  &__table {
    width: 100%;
  }

  .table-observer {
    // outline: 2px solid red !important;
    display: block;
    height: 2px;
  }

  .table-observer--right {
    width: 5px;
    height: 100%;
    display: block;
    align-self: stretch;
    flex-shrink: 0;
    min-height: 100%;
    position: static;
  }

  &--horizontal {
    .interactive-table {
      &__table {
        display: inline-flex;
      }

      &__not-found {
        display: none;
      }
    }
  }
}
