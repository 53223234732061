@import "Style/colors";
@import "Style/breakpoints";

.fc-number-input {
  display: block;
  font-size: 16px;

  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding: 0 15px;
    height: 48px;
    max-width: 100%;
  }

  &__control,
  &__field {
    position: relative;
    z-index: 2;
  }

  &__control {
    border: none;
    background-color: transparent;
    height: 48px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @f-color-service;
    cursor: pointer;

    &[disabled] {
      cursor: default;
      opacity: 0.5;
    }
  }

  &__field {
    border: none;
    background-color: transparent;
    height: 48px;
    flex-grow: 1;
    max-width: 100%;
    text-overflow: ellipsis;
    text-align: center;
    min-width: 0;

    &:focus {
      outline: none;
      border: none;
    }

    &::placeholder {
      color: @f-color-service;
    }
  }

  &__view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    border: 1px solid @f-color-border--dark;
    border-radius: inherit;
    pointer-events: none;
    font: inherit;
    color: @f-color-text;
    z-index: 0;
  }

  &__field:focus ~ .fc-number-input__view {
    border-color: @f-color-primary;
    box-shadow: 0 0 5px rgba(63, 101, 241, 0.5);
  }

  &--invalid {
    .fc-number-input__view {
      border-color: @f-color-danger;
      box-shadow: none;
    }

    .fc-number-input__field:focus ~ .fc-number-input__view {
      border-color: @f-color-danger;
      box-shadow: none;
    }
  }

  &--valid {
    .fc-number-input__view {
      border-color: @f-color-success;
      box-shadow: none;
    }
  }

  &--disabled {
    .fc-input__field {
      opacity: 0.5;
    }

    .fc-input__view {
      background-color: #f2f5f6;
    }
  }
}
