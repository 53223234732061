@modal-page-left-spacing: 30px;
@modal-page-right-spacing: 20px;

.modal.foquz-modal-page {
  padding: 0 !important;

  .modal-dialog {
    display: flex;
    flex-direction: row-reverse;

    width: 100%;
    height: 100%;
    margin: 0;
    padding-left: 200px;
  }
  .modal-content {
    position: relative;

    width: 100%;
    max-width: 1300px;

    border-radius: 0;
  }

  &.foquz-modal-page--sm {
    .modal-content {
      max-width: 610px;
    }
  }


  .modal-body {
    padding: 0;
  }

  .foquz-modal-page__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: rgba(255, 255, 255, 0.3);

    cursor: pointer;
    pointer-events: all;
  }

  .foquz-modal-page__close {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 35px;
    height: 35px;
    margin-left: -50px;
    margin-top: 10px;

    color: #73808d;
    background: transparent;

    border: 2px solid #73808d;
    box-shadow: none;
    outline: none;

    border-radius: 50%;

    font-size: 31px;

    cursor: pointer;
  }

  .foquz-modal-page__wrapper {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
  }

  .foquz-modal-page__header {
    padding-left: @modal-page-left-spacing;
    padding-right: @modal-page-right-spacing;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .foquz-modal-page__title {
    font-size: 22px;
    font-weight: 900;
    padding: 0;
    margin: 0;
  }

  .foquz-modal-page__body {
    flex-grow: 1;

    overflow: hidden;

    padding-left: @modal-page-left-spacing;
    padding-right: @modal-page-right-spacing;

    .ps {
      padding-right: 20px;
    }
  }

  .foquz-modal-page__full {
    margin-left: -1 * @modal-page-left-spacing;
    margin-right: -1 * @modal-page-right-spacing;
  }

  .foquz-modal-page__container {
    padding-left: @modal-page-left-spacing;
    padding-right: @modal-page-right-spacing;
  }

  .foquz-modal-page__footer {
    position: relative;

    width: 100%;
    min-height: 78px;

    padding-left: @modal-page-left-spacing;
    padding-right: @modal-page-right-spacing;
    padding-top: 20px;
    padding-bottom: 20px;

    background: #fbfbfb;
  }

  .foquz-modal-page__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}



.foquz-modal-wrapper {
  // Последний в стеке (не единственный)
  &.foquz-modal-wrapper--last:not(.foquz-modal-wrapper--single) {
    .foquz-modal-page {
    }
  }

  // Не последний в стеке (не единственный)
  &:not(.foquz-modal-wrapper--last):not(.foquz-modal-wrapper--single) {
    .foquz-modal-page {
      .modal-dialog {
        filter: blur(1px);
      }
      .modal-content {
        transform: translateX(-30px);
      }
      .foquz-modal-page__close {
        display: none;
      }
    }
  }
}

@import './media.less';
