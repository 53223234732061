.table-head-cell {
  &__name {
    display: flex;
    align-items: center;
    cursor: default;

    &.cursor-pointer {
      cursor: pointer !important;
    }
  }

  &[align="right"],
  [align="right"] & {
    .table-head-cell__name {
      justify-content: flex-end;
    }
    .f-table__search {
      text-align: right;
    }
  }
}

div.table-head-cell {
  display: block;
}
