.empty-section {
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    background: #eff2fa;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    max-width: 500px;
    margin-left: 50px;
  }
}
