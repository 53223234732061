.icon-type-sizes() {
  auto: 19, 17;
  manual: 17, 19;
}

.f-icon-type {
  each(.icon-type-sizes(), .(@v, @k) {
    &--@{k} {
      svg {
        width: 1px * extract(@v, 1);
        height: 1px * extract(@v, 2);
      }
    }
  });
}
