@import 'Style/typography';
@import 'Style/colors';
@import 'Style/breakpoints';

.foquz-stats-item {
  line-height: 1;
  display: block;
  border-right: 1px solid @f-color-border;
  padding: 15px 20px 15px;
  &.full {
    flex: 1 1 auto;
    max-width: 120px;
    word-break: break-word;
  }

  .value {
    font-size: @f-fs-h2;
    font-weight: 700;
    display: flex;

    small {
      font-weight: normal;
      font-size: 14px;
      align-self: flex-end;
    }
  }
  .label {
    font-size: @f-fs-1;
    font-weight: 500;
    color: @f-color-service;
    margin-top: 2px;
  }

  .f-icon--target {
    margin-top: -9px;
  }

  .only-mobile({
    &.adaptive {
      border-right: none;
      border-bottom: 1px solid @f-color-border;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 7px;
      padding-bottom: 7px;

      .value {
        font-size: 19px;
      }

      .label {
        margin-top: 0;
      }
    }

  });
}

.foquz-stats-group {
  display: flex;
}

.foquz-stats-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;

  .only-mobile({
    margin-left: -15px;
    margin-right: -15px;
  });
}
