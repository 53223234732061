@import 'Style/colors';

.button-ghost {
  padding: 0;
  border: 0;
  outline: 0;
  box-shadow: none;
  background: transparent;
  height: auto;

  &:focus {
    border: 0;
    outline: 0;
    box-shadow: none;
    background: transparent;
  }

  &-service {
    color: @f-color-service;

    &:hover,
    &:focus {
      &:not([disabled]) {
        color: @f-color-service--hover;
      }
    }
  }

  &[disabled] {
    opacity: 0.5;
  }
}
