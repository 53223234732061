.fc-nps-item {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-size: 13px;

    &[data-nps-design='2'] {
      background-color: #f1f5f6;
      color: black;
      border: 1px solid #cfd8dc;
    }
    &:not([data-nps-design='2']) {
      background-color: var(--nps-color);
      color: white;
    }
}