@import 'Style/typography';
@import 'Style/colors';

.check-input.input-radio {
  .check-input__icon {
    border-radius: 50%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: currentColor;
      opacity: 0;
      transition: opacity 250ms ease-in-out;
    }
  }

  input:checked ~ .check-input__icon {
    &:before {
      opacity: 1;
    }
  }
}
