@import 'Style/colors';

.fc-radio-group {
  display: flex;

  .fc-check {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 50%;
    padding: 10px 15px;
    min-height: 48px;
    border: 1px solid #CFD8DC;
    background-color: #F1F5F6;
    transition: background-color 400ms ease-in-out;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: -1px;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.checked {
      background-color: white;

    }

    &__box {
      background: white;
    }

    &--disabled {
      cursor: default;
      label {
        cursor: default;
      }
    }
  }
}
