.spinner {
  .fa {
    font-size: 2em;
  }

  &.sm {
    .fa {
      font-size: 1em;
    }
  }

}
