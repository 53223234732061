@import 'Style/colors';
@import 'Style/breakpoints';

.fc-media-wrapper {
  display: block;
  position: relative;
  flex-shrink: 0;

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--sm {
    .fc-media-wrapper__loading {
      width: 35px;
      height: 35px;
    }
    .fc-media-wrapper__label {
      width: 35px;
    }
  }

  &--lg {
    .fc-media-wrapper__loading {
      width: 105px;
      height: 105px;
    }
    .fc-media-wrapper__label {
      width: 105px;
    }
  }

  &__remove {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 2;

    .fc-btn-b {
      width: 20px;
      height: 20px;
    }
  }

  &--remove-on-hover {
    .fc-media-wrapper__remove {
      opacity: 0;

      .only-mobile({
        opacity: 1;
      });
    }

    &:hover {
      .fc-media-wrapper__remove {
        opacity: 1;
      }
    }
  }

  &__label {
    font-size: 12px;
    margin-top: 10px;
    word-break: break-word;
  }
}
