.fc-status-popper {
  z-index: 10000;
  .fc-popper__container {
    padding: 35px 30px;
  }

  &[data-mode="success"] {
    background: #abefb7;
  }
}
