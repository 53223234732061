@import "Style/colors";

.poll-info-button {
  .f-exclamation-mark {
    position: absolute;
    top: -6px;
    right: -6px;
  }
}

.poll-info-dropdown {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  width: 275px;

  &__item {
    padding: 10px;
    width: 50%;

    &--full {
      width: 100%;
    }

    position: relative;

    .copied-message {
      position: absolute;
      top: 60px;
      left: 0;
      background: #abefb7;
      padding: 30px;
      z-index: 2;
      font-size: 14px;
      box-shadow: 0px 5px 20px rgba(115, 128, 141, 0.4);
      border-radius: 9px;
      white-space: nowrap;
    }
  }

  &__label {
    color: @f-color-service;
    margin-bottom: 6px;
  }
}
