@import 'Style/colors';

.fc-range {
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;

  .noUi-tooltip {
    display: none;
  }

  &--tooltips {
    padding-top: 30px;
    .noUi-tooltip {
      display: block;
    }
  }

  &__slider {
    height: 6px;
    background: #e8edee;
    border: none;
    box-shadow: none;
    border-radius: 7px;

    &[disabled] {
      opacity: 0.5;
    }
  }

  .noUi-connect {
    background: #9fb2f8;
  }

  .noUi-handle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: @f-color-primary;
    top: -10px;
    right: -12px;
    border: none;
    box-shadow: none;

    &:before,
    &:after {
      display: none;
    }
  }

  .noUi-tooltip {
    border: none;
    color: @f-color-primary;
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    line-height: 1;
  }
}
