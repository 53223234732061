@import "Style/breakpoints";

.fc-popper {
  display: none;
  color: #333;
  background: white;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.3;
  border-radius: 9px;
  box-shadow: 0px 5px 20px rgba(115, 128, 141, 0.4);
  z-index: calc(var(--foquz-dialog-z-index) + 1)!important;

  &.fc-tooltip {
    background: #333;
    color: white;
    box-shadow: none;
  }

  &[data-show] {
    display: block;
  }

  &__container {
    padding: 10px 15px;
  }

  &__mask {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  &__arrow {
    visibility: hidden;
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 8px;
      height: 8px;
      background: inherit;
      visibility: visible;
      transform: rotate(45deg);
    }
  }

  &[data-popper-placement^="top"] > .fc-popper__arrow {
    bottom: -4px;
  }

  &[data-popper-placement^="bottom"] > .fc-popper__arrow {
    top: -4px;
  }

  &[data-popper-placement="bottom-start"] > .fc-popper__arrow {
    left: 4px!important;
    transform: none!important;
  }

  &[data-popper-placement="bottom-end"] {
    // margin-left: 5px !important;

    .fc-popper__arrow {
      margin-left: -5px;
    }
  }

  &[data-popper-placement^="left"] > .fc-popper__arrow {
    right: -4px;
  }

  &[data-popper-placement^="right"] > .fc-popper__arrow {
    left: -4px;
  }

  &__close {
    display: none;
  }

  &[data-view="modal"] {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;

    width: 100vw !important;
    height: 100vh !important;
    transform: none !important;
    min-width: 100vw !important;
    min-height: 100vh !important;
    max-width: none !important;
    background-color: transparent;
    margin: 0 !important;

    .fc-popper__scroll {
      max-height: 100%;
      overflow: auto;
      width: 100%;
    }

    .fc-popper__container {
      margin: 20px auto;
    }

    .fc-popper__mask {
      display: block;
    }

    .fc-popper__close {
      display: flex;
      justify-content: flex-end;
      height: 12px;
      margin-bottom: 10px;

      .fc-btn {
        position: absolute;
        top: 15px;
        right: 15px;
      }

      .fc-icon {
        width: 12px;
        height: 12px;
      }
    }

    .fc-popper__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fc-popper__container {
      background-color: white;
      width: 280px;
      box-shadow: 0px 5px 20px rgba(115, 128, 141, 0.4);
      border-radius: 9px;
      padding-top: 15px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      z-index: 2;
      position: relative;
    }

    &[data-popper-reference-hidden] {
      visibility: visible;
      opacity: 1;
    }
  }
}

body {
  .only-mobile({
    &[data-popper-open] {
      overflow: hidden;
    }
  });
}
