.info-dialog {
  &[data-view="mobile-sm"] {
    .foquz-dialog {
      &__container {
        padding-left: 20px;
        padding-right: 20px;
      }
      .foquz-dialog__content {
        height: auto;
        flex-grow: 0;
        border-radius: 9px;
      }
    }
  }
}
