@import 'Style/breakpoints';

.mailing-name {
  .mobile-and-tablet({
    padding-left: var(--foquz-side-offset);
    padding-right: var(--foquz-side-offset);

    .page-header {
      padding-left: 0;
    }
  });
  .btn-icon-edit[disabled] {
    &,
    &:hover {
      background: white !important;
    }
  }
}
