@import 'Style/colors';

.fc-horizontal-table {
  display: block;
  &__wrapper {
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  &__header {
    flex-shrink: 0;
    max-width: 100px;
  }

  &-header {
    &__cell {
      padding-left: 15px;
      padding-right: 12px;
      padding-top: 8px;
      padding-bottom: 8px;

      border: 1px solid #E7EBED;
      border-right: 0;

      font-size: 12px;
      font-weight: bold;
    }

    thead {
      .fc-horizontal-table-header__cell {
        border-bottom-width: 2px;
      }
    }

  }

  &__table {
    flex-grow: 1;
    flex-basis: 100%;
    overflow: hidden;


    position: relative;

    .fc-scroll {
      width: 100%;

      &__wrapper {
        padding-bottom: 10px;
      }
    }

    th {
      max-width: 0!important;
      overflow: hidden;
    }
  }

  &-original-table {
    min-width: 100%;
    td, th {
      border: 1px solid #E7EBED;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 12px;

      min-width: var(--fc-horizontal-table-cell-width);

      &:first-child {
        border-left: 0;
      }
    }

    thead {
      td, th {
        border-bottom-width: 2px;
      }
    }

    &__hidden {
      display: none;
      visibility: hidden;
    }
  }

  &__shadow {
    position: absolute;
    top: 0;
    bottom: 10px;
    width: 10px;
    background: linear-gradient(90deg, #ECEFF1 0%, rgba(236, 239, 241, 0) 100%);
  }

  .fc-scroll--touch + &-shadow {
    bottom: 0px;
  }

  &__loader, &__empty {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 9px;

    border: 1px solid #E7EBED;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &__empty {
    color: @f-color-service;
  }

  .fc-scroll--touch + &__loader,
  .fc-scroll--touch + &__empty {
    bottom: 0px;
  }
}
