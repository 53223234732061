@import 'Style/colors.less';

.foquz-select2 {
  .select2-results {
    &__option {
      &[role='option'] {
        .fixed {
          flex-grow: 1;

          position: relative;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -6px;
            border-bottom: 1px solid @f-color-border;
          }
        }

        .user-option {
          &__name {
            color: @f-color-text!important;
          }
          &.fixed {
            .user-option__userpic {
              position: relative;
              &:before {
                content: '';
                position: absolute;
                top: -3px;
                left: -3px;
                right: -3px;
                bottom: -3px;
                border-radius: 10px;
                background-color: @f-color-primary;
                z-index: 1;
              }
              .userpic {
                z-index: 2;
              }
            }

            .user-option__name {
              font-weight: bold;
            }
          }
        }
      }

      &.loading-results {
        padding: 0 10px !important;
      }
    }

    &__option:only-child {
      &[role='option'] {
        .fixed {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
