@import 'Style/colors';
@import 'Style/breakpoints';
.fc-period-calendar {
  display: inline-block;

  &__wrapper {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
  }

  &__from,
  &__to {
    margin-left: 10px;
    margin-right: 10px;
  }

  &__from {
    .fc-month-calendar__next {
      visibility: hidden;
    }
  }

  &__to {
    .fc-month-calendar__prev {
      visibility: hidden;
    }
  }

  &__field {
    margin-bottom: 10px;
  }

  &__tabs {
    display: none;
  }

  .only-mobile({
    &__tabs {
      display: flex;
      padding-bottom: 10px;
      margin-bottom: 10px;

      position: relative;

      &:before {
        content: '';
        position: absolute;
        border-bottom: 1px solid #cfd8dc;
        bottom: 0;
        left: -20px;
        right: -20px;
      }
    }

    &__tab {
      flex-grow: 1;
      flex-basis: 50%;
      text-align: center;
      &.active {
        color: @f-color-primary;
      }
    }

    &__wrapper {
      margin-left: 0;
      margin-right: 0;
      justify-content: center;
    }

    &__from, &__to {
      margin-left: 0;
      margin-right: 0;
      &:not(.active) {
        display: none;
      }
    }

    &__from {
      .fc-month-calendar__next {
        visibility: visible;
      }
    }

    &__to {
      .fc-month-calendar__prev {
        visibility: visible;
      }
    }
  });
}
