@import 'Style/colors';
@import 'Style/breakpoints';

.file-loader-preview {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 94px;
  height: 94px;

  border-radius: 8px;
  background-color: #eceff1;

  &--lg {
    width: 105px;
    height: 105px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: inherit;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__bg-overlay {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {

      width: 48px;
      height: 48px;
    }
    &_audio {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 2px solid #DADFE3;
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__remove {
    position: absolute;
    top: -6px;
    right: -6px;

    opacity: 0;
    transition: opacity 400ms;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;

    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 50%;

    background-color: black;
    color: white;

    font-size: 11px;

    cursor: pointer;
  }

  &__content {
    position: relative;
    z-index: 2;
  }

  &:hover .file-loader-preview__remove {
    opacity: 1;
  }

  &.is-invalid {
    box-shadow: 0 0 0 1px @f-color-danger;
  }

  .mobile-and-tablet({
    &__remove {
      opacity: 1;
    }
  });
}
