.lang-toggler {
  display: block;

  .f-btn {
    font-weight: normal;
    font-size: 14px;
    width: 100%;
    text-align: left;

    .text {
      flex-grow: 1;
    }

    a {
      display: flex;
    }
  }
  .dropdown-wrapper {
    max-height: 250px;
  }
  .dropdown-close {
    display: none;
  }

  &--modal {
    .dropdown-menu.show {
      position: fixed!important;
      top: 0 !important;
      left: 0;
      right: 0;
      bottom: 0;
      transform: none !important;
      border-radius: 0;
      margin: 0;
      padding: 20px;
      background: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .dropdown-wrapper {
      background: white;
      box-shadow: 0px 5px 20px rgba(115, 128, 141, 0.4);
      border-radius: 9px;
      width: 280px;
      padding-top: 30px;
      padding-bottom: 20px;
      position: relative;
      min-height: 100px;
    }
    .dropdown-close {
      position: absolute;
      top: 15px;
      right: 15px;
      display: block;
    }
  }
}
