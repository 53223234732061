.site-section-icon-sizes() {
  mailings: 24, 18;
  users: 28, 31;
  companies: 24, 23;
  points: 26, 27;
  clients: 28, 28;
  reports: 23, 19;
  help: 30, 28;
  payments: 27, 25;
  settings: 23, 25;
  answers: 22, 23;
  polls: 23, 21;
  projects: 28, 24;
}

.f-icon-site-section {
  width: 32px;
  height: 32px;

  each(.site-section-icon-sizes(), .(@v, @k) {
    &--@{k} {
      svg {
        width: 1px * extract(@v, 1);
        height: 1px * extract(@v, 2);
      }
    }
  });
}
