.icon-channel-set() {
  email: 20, 20, @f-color-service;
  sms: 21, 21, @f-color-service;
  viber: 20, 20, @f-color-viber;
  telegram: 19, 16, @f-color-telegram;
  push: 18, 19, @f-color-service;
  link: 19, 14, @f-color-service;
  repeats: 18, 21, inherit;
}
.f-icon-channel {
  each(.icon-channel-set(), .(@v, @k) {
    &--@{k} {
      svg {
        width: 1px * extract(@v, 1);
        height: 1px * extract(@v, 2);
      }

      color: extract(@v, 3);
    }
  });

  &--lg {
    width: 28px;
    height: 28px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
