.svg-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;

  &--xs {
    width: 8px;
    height: 8px;
  }

  &--sm {
    width: 12px;
    height: 12px;
  }

  &--md {
    width: 16px;
    height: 16px;
  }

  &--lg {
    width: 20px;
    height: 20px;
  }

  &--xl {
    width: 24px;
    height: 24px;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
