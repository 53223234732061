.poll-info-dialog {
  .foquz-dialog__container {
    width: 280px;
  }
  .foquz-dialog__header {
    padding-top: 16px !important;
  }
  .foquz-dialog__body {
    padding-left: 20px;
    padding-right: 20px;
  }
  &__label {
    margin-bottom: 6px;
  }

  .copied-message {
    position: absolute;
    top: 60px;
    left: 0;
    background: #abefb7;
    padding: 30px;
    z-index: 2;
    font-size: 14px;
    box-shadow: 0px 5px 20px rgba(115, 128, 141, 0.4);
    border-radius: 9px;
    white-space: nowrap;
  }
}
