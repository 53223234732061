:root {
  --fc-rating-bg-color: #ffffff;
  --fc-rating-icon-size: 34px; // размер иконки
  --fc-rating-icon-scale-ratio: 1.3; // коэффициент увеличения выбранной иконки
  --fc-rating-item-size: 34px; // размер элемента
  --fc-rating-item-gap: 13px; // половина расстояния между элементами
  
  --fc-rating-item-label: 13px; // размер метки элемента
  --fc-rating-item-label-offset: 15px; // отступ сверху метки элемента
  --fc-rating-item-label-width: 100px; // максимальная ширина метки элемента
  --fc-rating-label: 16px; // размер общей метки
  --fc-rating-label-offset: 15px; // отступ сверх общей метки
}

.fc-img-rating {
  &__items {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    margin-left: calc(-1 * var(--fc-rating-item-gap));
    margin-right: calc(-1 * var(--fc-rating-item-gap));
  }

  &-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-left: var(--fc-rating-item-gap);
    margin-right: var(--fc-rating-item-gap);
    width: var(--fc-rating-item-size);

    flex-shrink: 0;

    &__icon {
      width: var(--fc-rating-icon-size);
      height: var(--fc-rating-icon-size);
      will-change: transform;
      transition: transform 250ms;
      font-size: 0;
      object-fit: contain;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__label {
      width: 100%;
      max-width: var(--fc-rating-item-label-width);
      text-align: center;
      margin-top: var(--fc-rating-item-label-offset);
      font-weight: bold;
      font-size: var(--fc-rating-item-label) !important;
      white-space: nowrap;
      overflow: hidden;
      position: relative;

      &:empty {
        margin-top: 0;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 10px;
        background: linear-gradient(
          to left,
          var(--fc-rating-bg-color),
          transparent
        );
      }
    }

    &.active {
      .fc-img-rating-item__icon {
        transform: scale(var(--fc-rating-icon-scale-ratio));
      }
    }
  }

  &--inited {
    .fc-img-rating-item:not(.active) {
      opacity: 0.4;
    }
  }

  &__label {
    text-align: center;
    margin-top: var(--fc-rating-label-offset);
    font-weight: bold;
    font-size: var(--fc-rating-label);
  }

  &[data-icons="2"] {
    --fc-rating-item-size: 60px;
    --fc-rating-icon-size: 60px;
    --fc-rating-icon-scale-ratio: 1.4;
    --fc-rating-item-gap: 30px;
  }
  &[data-icons="3"] {
    --fc-rating-item-size: 46px;
    --fc-rating-icon-size: 46px;
    --fc-rating-icon-scale-ratio: 1.3;
    --fc-rating-item-gap: 30px;
  }
  &[data-icons="5"] {
    --fc-rating-item-size: 34px;
    --fc-rating-icon-size: 34px;
    --fc-rating-icon-scale-ratio: 1.4;
    --fc-rating-item-gap: 13.5px;
  }

  &[data-type="heart"], &[data-type="like"] {
    --fc-rating-item-size: 66px;
    --fc-rating-icon-size: 66px;
    --fc-rating-item-scale-ratio: 1.2;
  }

  @media screen and (max-width: 767.98px) {
    &[data-icons="2"] {
      --fc-rating-item-gap: 19px;
    }
    &[data-icons="3"] {
      --fc-rating-item-gap: 19px;
    }
    &[data-icons="5"] {
      --fc-rating-item-gap: 12.5px;
    }
  }

  &.fc-img-rating--labels {
    --fc-rating-item-size: 100px;
    --fc-rating-item-gap: 5px;
  
    @media screen and (max-width: 767.98px) {
      &[data-icons="2"] {
        --fc-rating-item-size: 60px;
        --fc-rating-item-gap: 15px;
      }
      &[data-icons="3"] {
        --fc-rating-item-size: 54px;
        --fc-rating-item-gap: 15px;
      }
      &[data-icons="5"] {
        --fc-rating-item-size: 54px;
        --fc-rating-item-gap: 2.5px;
      }
    }
  }
}


