@import 'Style/colors';

.f-btn {
  position: relative;

  .f-badge {
    position: absolute;
    width: 14px;
    height: 14px;
    top: -7px;
    right: -7px;
    border-radius: 50%;
    color: white;
    background: @f-color-service;

    &--success {
      background: @f-color-success;
    }

    svg-icon, svg {
      pointer-events: none;
    }
  }
}
