@import 'Style/colors';
@import 'Style/breakpoints';

[data-dropdown-target] {
  cursor: pointer;
}

[data-tippy-root] {
  z-index: 1049 !important;
}

.tippy-content {
  padding: 15px;

  background: white;
  box-shadow: 0px 5px 15px rgba(46, 47, 49, 0.3);
  border-radius: 9px;

  .tippy-list {
    margin-left: -15px;
    margin-right: -15px;
    &.tippy-list-ansver-actions {
      .tippy-list__item {
        padding-left: 20px;
        padding-right: 35px;
        color: #3F65F1;
        &.disabled {
          opacity: 1;
          cursor: not-allowed;
  
          &:hover {
            background: transparent;
          }
          span {
            opacity: .5;
          }

      }
      
      }
    }
    &__item {
      display: block;

      padding-left: 15px;
      padding-right: 15px;
      padding-top: 7px;
      padding-bottom: 7px;

      text-decoration: none;

      &:hover {
        background: #f1f5f6;

        text-decoration: none;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &:hover {
          background: transparent;
        }
      }
      .beta-icon {
        top: 7px;
        right: 22px;
      }

      &--danger {
        color: #F96261;        
      }
    }
  }

  
}

.tippy-svg-arrow {
  fill: white;
}

.tippy-mode--button {
  .tippy-svg-arrow {
    transform: none !important;
    left: auto !important;
    right: 10px;
  }
}

.tippy-box {
  max-width: none !important;
}

.tippy-mode--select {
  .only-mobile({
    position: fixed!important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%!important;
    height: 100%!important;
    transform: none!important;

    .tippy-box {
      width: 100%!important;
      height: 100%!important;
    }

    .tippy-content {
      border-radius: 0;
      width: 100%!important;
      height: 100%!important;
    }

    .tippy-wrapper {
      width: 100%!important;
      height: 100%!important;
      display: flex;
      flex-direction: column;
    }
  });
}

.tippy-mode--modal {
  .only-mobile({
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
    transform: none !important;

    .tippy-box {
      width: 100% !important;
      height: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .tippy-content {
      width: 280px;
    }
  });
}
