.foquz-modal {
  .modal-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
  .modal-dialog {
    width: 460px;

    .modal-content {
      box-shadow: 0px 5px 20px rgba(115, 128, 141, 0.4);
      border-radius: 9px;

      .modal-header {
        padding: 38px 30px 11px;
        position: relative;
        border-bottom: 0;
      }

      .modal-body {
        padding: 11px 30px 32px;

        .foquz-modal__full {
          margin-left: -30px;
          margin-right: -30px;
          padding-left: 30px;
          padding-right: 30px;
        }
      }

      .modal-footer {
        min-height: 75px;
        border-top: none;
        background: #fbfbfb;
        padding: 20px 30px;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
      }
    }
  }
}
