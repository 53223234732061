.fc-load-button {
  input[type='file'] {
    visibility: none;
    position: absolute;
    opacity: 0;
  }
  button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    outline: none;
    box-shadow: none;
  }
}