.edit-form {
  display: flex;
  align-items: center;
  overflow: hidden;

  .edit-form__cancel, .edit-form__save {
    width: 34px;
    height: 34px;
    flex-shrink: 0;
  }

  .edit-form__cancel {
    margin-left: 10px;

    &.edit-form__save {
      margin-left: 5px;
    }
  }

  &__value {
    padding: 0;
    font-size: 22px;
    font-weight: 900;
    color: #2e2f31;
    border: none;
    background: none;
    border-bottom: solid 1px #a6b1bc;
  }

  &__hidden {
    position: absolute;
    visibility: hidden;
    padding-left: 10px;
    font-size: 22px;
    font-weight: 900;
    white-space: pre;
  }
}
