@import 'Style/colors';
@import 'Style/breakpoints';

.fc-media {
  display: block;
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &--cover {
    img {
      object-fit: cover;
    }
  }

  &--lg {
    width: 105px;
    height: 105px;
  }
}
