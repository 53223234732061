.star-rating {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;

  &__stars {
    display: flex;
    align-items: center;
  }

  .svg-icon {
    width: 18px;
    height: 18px;
    margin: 4px 4px;
  }

  &__label {
    font-size: 16px;
    font-weight: 900;
    line-height: 13px;
    margin-left: 4px;
    margin-right: 4px;
  }

  &--lg {
    margin-left: -8px;
    margin-right: -8px;
    .svg-icon {
      width: 28px;
      height: 28px;
      margin-left: 8px;
      margin-right: 8px;
    }

    .star-rating__label {
      font-size: 19px;
      margin-left: 11px;
      margin-right: 8px;
    }
  }
}
