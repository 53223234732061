@import 'Style/colors';

.icon-check-loading(@color) {
	@svg-content: escape(
		'<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.063 2.813c0-.56-.215-1.032-.602-1.461C12.03.965 11.559.75 11 .75c-.602 0-1.074.215-1.46.602-.43.43-.602.902-.602 1.46 0 .602.171 1.075.601 1.461.387.43.86.602 1.461.602.559 0 1.031-.172 1.46-.602.388-.386.602-.859.602-1.46zM11 18.624c.559 0 1.031.215 1.46.602.388.43.602.902.602 1.46 0 .602-.214 1.075-.601 1.461-.43.43-.902.602-1.461.602-.602 0-1.074-.172-1.46-.602-.43-.386-.602-.859-.602-1.46 0-.56.171-1.032.601-1.461.387-.387.86-.602 1.461-.602zm8.938-8.938c.558 0 1.03.215 1.46.602.387.43.602.902.602 1.461 0 .602-.215 1.074-.602 1.46-.43.43-.902.602-1.46.602-.602 0-1.075-.171-1.461-.601-.43-.387-.602-.86-.602-1.461 0-.559.172-1.031.602-1.46.386-.388.859-.602 1.46-.602zM4.124 11.75c0 .602-.215 1.074-.602 1.46-.43.43-.902.602-1.46.602-.602 0-1.075-.171-1.461-.601C.172 12.824 0 12.35 0 11.75c0-.559.172-1.031.602-1.46.386-.388.859-.602 1.46-.602.56 0 1.032.214 1.461.601.387.43.602.902.602 1.461zm.559 4.254c.558 0 1.03.215 1.46.601.387.43.602.903.602 1.461 0 .602-.215 1.075-.601 1.461-.43.43-.903.602-1.461.602-.602 0-1.075-.172-1.461-.602-.43-.386-.602-.859-.602-1.46 0-.56.172-1.032.602-1.462.386-.386.859-.601 1.46-.601zm12.632 0c.559 0 1.032.215 1.461.601.387.43.602.903.602 1.461 0 .602-.215 1.075-.602 1.461-.43.43-.902.602-1.46.602-.602 0-1.075-.172-1.462-.602-.43-.386-.601-.859-.601-1.46 0-.56.172-1.032.601-1.462.387-.386.86-.601 1.461-.601zM4.684 3.37c.558 0 1.03.215 1.46.602.387.43.602.902.602 1.46 0 .602-.215 1.075-.601 1.462-.43.43-.903.601-1.461.601-.602 0-1.075-.172-1.461-.601-.43-.387-.602-.86-.602-1.461 0-.559.172-1.032.602-1.461.386-.387.859-.602 1.46-.602z" fill="@{color}"/></svg>'
	);
	background-image: url('data:image/svg+xml,@{svg-content}') !important;
	background-size: auto;
	width: 22px;
	height: 23px;
	background-repeat: no-repeat;
}

@keyframes rotate {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}

.f-btn--loading {
	position: relative;
	color: transparent !important;

	.f-btn-prepend,
	.f-btn-append {
		opacity: 0;
	}

	&:before {
		content: '';
		position: absolute;
		margin: auto;

		display: inline-flex;
		.icon-check-loading(@f-color-service);
		animation: rotate 4s linear infinite;
	}

	&.f-btn-success {
		&:before {
			.icon-check-loading(@f-color-success);
		}
	}
}
