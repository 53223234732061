@import 'Style/colors';

.fc-select-click {
  display: block;
  position: relative;

  &__curtain {
    display: block;
  }

  @media screen and (min-width: 768px) {
    &__curtain {
      display: none;
    }
  }
}

@media screen and (max-width: 767.98px) {
  .select2--test-select {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &.opening {
      pointer-events: none;
    }

    .select2-dropdown {
      max-width: 280px !important;
      min-width: 280px !important;
      height: auto !important;
      position: relative !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background: white;
      border-radius: 8px;
      padding: 30px;
      padding-top: 40px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;

      &:before {
        display: none;
      }
      .select2-results__options {
        padding-right: 20px;
      }

      .ps__rail-y {
        right: 0;
      }

      .select2-search {
        display: none;
      }

      .select2-results {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-bottom: 12px;

        &__options {
          max-height: none;
        }

        &__option {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }

      .select2-dropdown__top-close {
        display: block;
        position: absolute;
        top: 2px;
        right: 10px;
        background: transparent;
        border: none;
        outline: none;
        font-size: 24px;
      }

      .select2-dropdown__close {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          &:before {
            content: 'Отменить';
          }

          background: transparent;
          border: none;
          outline: none;
          color: @f-color-primary;

          svg {
            display: none;
          }
        }
      }
    }

    .select2-results__options {
      padding-right: 0 !important;
    }

    .select2-results__option {
      &[role='option'] {
        font-size: 22px;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .select2-results {
      padding-top: 0;
    }
  }

  .select2--request-theme {
    .select2-dropdown {
      .select2-dropdown__close {
        display: none;
      }
    }
  }
}
