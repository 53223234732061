.period-picker {
  &__close {
    display: none;
  }
  &--modal {
    // .period-picker__arrow {
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background-color: rgba(0, 0, 0, 0.5);
    //   background-image: none!important;
    //   cursor: pointer;
    //   width: 100%!important;
    //   height: 100%!important;
    // }
  }
  .select2 {
    display: inline-block;
  }
  .monthselect  {
    // width: 200px;

    & + .select2 {
      min-width: 120px;
      margin-right: 30px;
    }
  }
  // .yearselect {
  //   width: 120px;

  //   & + .select2 {
  //     min-width: 120px;
  //   }
  // }
}
