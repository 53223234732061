@import 'Style/colors';

.fc-dropdown-list {
  margin-left: -15px;
  margin-right: -15px;

  &__item {
    display: block;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 30px;
    display: flex;
    align-items: center;

    &:hover {
      background: @f-color-highlight;
      text-decoration: none;
    }

    &.disabled:not(.with-pointer-events) {
      pointer-events: none;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
