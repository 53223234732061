/*
 * Container style
 */
 .ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  position: relative;
  height: 100%;
}

.ui-autocomplete.ps {
  position: absolute!important;
}

/*
* Scrollbar rail styles
*/
.ps__rail-x {
  display: none;

  height: 4px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
  z-index: 10;
}

.ps__rail-y {
  display: none;

  width: 4px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
  z-index: 10;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: #e8edee;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  //opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #e8edee;
  //opacity: 0.9;
}

/*
* Scrollbar thumb styles
*/
.ps__thumb-x {
  background-color: #8e99a3;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 4px;
  bottom: 0px;
  position: absolute;
  cursor: pointer;
}

.ps__thumb-y {
  background-color: #8e99a3;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 4px;
  right: 0px;
  position: absolute;
  cursor: pointer;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #8e99a3;
  height: 10px;
  bottom: -3px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #8e99a3;
  width: 10px;
  right: -3px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.ps-wrapper {
  position: relative;
  height: 100%;

  &.select2-container {
    height: auto;
  }

  .ps-x-gradient {
    display: none;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      top: 0;
      width: 35px;
      content: '';
      z-index: 8;
      transition: opacity 400ms;
    }

    &:before {
      left: 0;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
    }

    &:after {
      right: 0;
      background: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &.ps-x-gradient--disable-start {
      &:before {
        display: none;
      }
    }
    &.ps-x-gradient--disable-end {
      &:after {
        display: none;
      }
    }
  }

  .ps.ps--active-x + .ps-x-gradient {
    display: block;
  }
  .ps.ps-x-reach-start + .ps-x-gradient {
    &:before {
      opacity: 0;
    }
  }
  .ps.ps-x-reach-end + .ps-x-gradient {
    &:after {
      opacity: 0;
    }
  }

  .ps-y-gradient {
    display: none;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:before,
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      height: 35px;
      content: '';
      z-index: 8;
      transition: opacity 400ms;
    }

    &:before {
      top: 0;
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
    }

    &:after {
      bottom: 0;
      background: linear-gradient(
        to top,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &.ps-y-gradient--disable-start {
      &:before {
        display: none;
      }
    }
    &.ps-y-gradient--disable-end {
      &:after {
        display: none;
      }
    }
  }

  .ps.ps--active-y + .ps-y-gradient {
    display: block;
  }
  .ps.ps-y-reach-start + .ps-y-gradient {
    &:before {
      opacity: 0;
    }
  }
  .ps.ps-y-reach-end + .ps-y-gradient {
    &:after {
      opacity: 0;
    }
  }

  &--select {
    .ps__rail-y {
      left: auto !important;
      right: 20px;
    }
  }
}
