@import 'Style/typography';
@import 'Style/colors';


.check-input.input-checkbox {
  .check-input__icon {
    border-radius: 4px;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 10px;
      height: 8px;
      opacity: 0;
      transition: opacity 250ms ease-in-out;
    }
  }

  input:checked ~ .check-input__icon {
    svg {
      opacity: 1;
    }
  }

  &.input-checkbox--partially {
    .check-input__icon {
      svg {
        display: none;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 8px;
        height: 8px;
        border-radius: 2px;
        background: currentColor;
        opacity: 0;
        transition: opacity 250ms ease-in-out;
      }
    }

    input:checked ~ .check-input__icon {
      &:before {
        opacity: 1;
      }
    }
  }
}
