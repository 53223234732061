.fc-progress {
  display: flex;
  align-items: center;
  width: 100%;

  &__line {
    background-color: currentColor;
    height: 4px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    transition: width 250ms ease-in-out;
  }
}
