.fc-btn {
  &-b {
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;

    &--sm {
      height: 32px;
      font-size: 13px;
      font-weight: 400;
      padding-left: 15px;
      padding-right: 15px;
    }

    &--full {
      width: 100%;
    }

    &--xl {
      height: 48px;
    }

    &--auto {
      height: auto;
      padding: 0;

      &.fc-btn-b--label {
        padding-left: 0;
      }
    }

    &--mode_text {
      padding: 0;
      height: auto;
    }
  }
}
