
@import 'Style/colors';



.drp-root {
  .calendar-dropdown {
    pointer-events: all;
    &__close {
      display: none;
      position: absolute;
      top: 12px;
      right: 15px;
      z-index: 2;
      font-size: 0;

      button {
        padding: 0;
        &,
        &:hover {
          background: transparent;
          border: none;
          outline: none;
        }
      }
    }

    .monthselect[aria-hidden='true'],
    .yearselect[aria-hidden='true'] {
      visibility: hidden;
      position: absolute!important;
      clip: rect(0, 0, 0, 0)!important;
    }

    .monthselect + .select2 {
      margin-right: 10px;
      min-width: 160px;
    }

    .yearselect + .select2 {
      width: 70px!important;

      .select2-selection__rendered {
        padding: 0!important;
      }
    }

    .drp-calendar {
      padding-top: 15px!important;
      padding-left: 20px!important;
      padding-right: 20px!important;
      // float: none!important;
      max-width: 280px;

      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      font-size: 14px;

      td {
        font-size: 14px;
      }

      thead th {
        height: 0;
        padding-bottom: 12px;
      }

      &.single {
        .calendar-table {
          padding-right: 0;
        }
      }
    }

    .select2 {
      border: 1px solid @f-color-border--dark;
      border-radius: 3px;

      &-selection {
        width: 100%;
        height: 24px;
        text-align: left;
        padding: 0 10px;

        &__rendered {
          font-size: 13px!important;
        }
        &__arrow {
          opacity: 0.7;

          b {
            width: 10px!important;
            height: 6px!important;
          }
        }
      }
    }

    &--single {
      width: 280px;
      min-width: 280px;

      .drp-calendar {
        padding: 16px 20px;
      }
    }

    .ranges {
      display: none;
    }
  }

  &[data-mode='modal'] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    pointer-events: none;

    .drp-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .drp-mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: none;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      z-index: 99;
      pointer-events: all;
    }

    .calendar-dropdown {
      top: auto !important;
      left: auto !important;
      right: auto !important;
      bottom: auto !important;
      margin: auto;
      z-index: 100;

      &__arrow {
        display: none;
      }

      &__close {
        display: block;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }

    .drp-calendar {
      position: relative;
      z-index: 2;
      padding-top: 28px!important;
    }
  }
}
