@import 'Style/breakpoints';

.poll-name-form {
  display: flex;
  max-width: 100%;
  width: 100%;

  .breadcrumbs {
    overflow: hidden;

    &__item {
      font-weight: 900;
      font-size: 22px;
      color: #73808d !important;

      &:not(:last-child) {
        &:after {
          content: '\00a0\00a0/\00a0\00a0';

          color: #73808d !important;
        }
      }

      &:last-child {
        color: #2e2f31 !important;
      }
    }
  }

  &__test {
    margin-top: -2px;
    margin-right: 10px;
  }



  .poll-name-form__edit {
    width: 34px;
    height: 34px;
    margin-left: 8px;
    flex-shrink: 0;

    .mobile-and-tablet({
      display: none;
    });
  }

  .poll-name-form__type {
    flex-shrink: 0;
    margin-right: 12px;
    align-self: flex-start;
    margin-top: 6px;
  }

  &__alarm {
    margin-right: 10px;
  }
}


.user--filial-employee {
  .poll-name-form {
    &__edit {
      display: none;
    }
  }
}
