@import 'Style/colors';

.meta-row-item() {
  border-top: 1px solid @f-color-border;
  border-bottom: 1px solid @f-color-border;
  margin-top: -1px;
}

.meta-row-filler() {
  &:after {
    content: '';
    display: block;
    flex-grow: 999;
    .meta-row-item();
  }
}

.meta-row {
  .meta-row-filler();
  border-top: 1px solid @f-color-border;
  display: flex;
  flex-wrap: wrap;

  .meta-item,
  .meta-group {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    button {
      margin: auto;
      margin-left: 30px;
    }
    .csv-button {
      border-right: 1px solid @f-color-border;
      padding: 19px 15px;
    }
  }
  .meta-group-right {
    order: 5;
    margin-left: auto;
  }

  .meta-group {
    .meta-row-filler()
  }

  .meta-item {
    .meta-row-item();
  }

  .meta-item--running {
    width: 80%;
  }
}
