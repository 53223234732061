.icon-sizes() {
  // дата, время
  calendar: 20, 21;
  time: 22, 22;

  // действия на кнопках
  save: 16, 15;
  bin: 18, 18;
  plus: 14, 14;

  // статистика
  target: 22, 22;
  star: 17, 16;
  empty-star: 17, 16;

  // медиа
  picture: 14, 14;
  picture-2: 24, 24;
  load: 19, 16;
  upload: 18, 16;

  // соц. сети
  fb: 34, 34;
  vk: 34, 34;
  inst: 34, 34;

  // сервис
  settings: 19, 20;
  settings-warning: 16, 17;
  ellipsis: 19, 4;
  search: 18, 17;
  check: 22, 16;
  back: 19, 14;
  pencil: 15, 15;
  eye-visible: 18, 12;
  eye-hidden: 20, 17;
  copy: 19, 21;
  filter: 11, 11;
  expand: 14, 14;
  print: 17, 17;

  // рассылки
  start: 18, 18;
  stop: 18, 18;
  restart: 16, 16;

  // прочие
  envelope: 19, 14;
  list-check: 18, 16;
  folder: 17, 15;
  user-bg: 30, 30;
  user: 18, 16;
  link: 20, 15;
  outer-link: 11, 11;
  repeats: 14, 14;
  excel: 14, 14;
  comment: 22, 23;
  message: 14, 14;
  logout: 14, 14;
  exclamation-circle: 16, 16;

  arrow-top: 14, 8;
  arrow-bottom: 14, 9;
}

.f-icon {
  each(.icon-sizes(), .(@v, @k) {
    &--@{k} {
      svg {
        width: 1px * extract(@v, 1);
        height: 1px * extract(@v, 2);
      }
    }
  });

  &--target {
    svg {
      color: @f-color-gold;
    }
  }

  &--star,
  &--empty-star {
    svg {
      color: @f-color-yellow;
    }
  }

  &--sorting-order {
    width: 10px;
    height: 10px;

    svg {
      width: 8px;
      height: 5px;
    }
  }

  &--filter {
    width: 12px;
    height: 12px;

    svg {
      width: 11px;
      height: 11px;
    }
  }

  &--start {
    color: #16CEB9;
  }
}
