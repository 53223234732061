.userpic {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &--round {
    border-radius: 50%;
  }

  &.lg {
    width: 40px;
    height: 40px;
  }
}
