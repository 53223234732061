.fc-btn {
  display: inline-block;
  padding: 0;
  margin: 0;
  line-height: 0;

  &--block {
    display: block;

    .fc-btn-b {
      width: 100%;
    }
  }

  &-b {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-family: inherit;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    white-space: nowrap;

    &,
    &:hover,
    &:focus {
      border: 0;
      outline: 0;
      box-shadow: none;
      text-decoration: none;
    }

    &__pending {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }

    &__pending ~ .fc-btn-b {
      &__icon, &__label {
        visibility: hidden;
      }
    }

    &--disabled {
      opacity: 0.5;
      cursor: default!important;
    }
  }




}

@import './css/colors';
@import './css/icons';
@import './css/sizes';
@import './css/shapes';
