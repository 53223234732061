.copy-input {
  display: block;
  position: relative;

  .copy-input__field {
    padding-right: 50px;
    text-overflow: ellipsis;

    &[disabled] {
      background: #F1F5F6 !important;
    }
  }

  .copy-input__action {
    position: absolute;
    z-index: 3;

    width: 32px;
    height: 32px;
    top: 10px;
    right: 10px;

    svg {
      transition: transform 250ms ease-in-out;
    }

    &:hover {
      svg {
        transform: scale(1.2);
      }

      color: #2e2f31;
    }
  }
}
