.progress-line {
  display: block;

  position: relative;
  height: 4px;
  background-color: #eceff1;
  border-radius: 5px;
  &__indicator {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #2d99ff;
    border-radius: 5px;

    transition: width 400ms ease-in-out;
  }
}
