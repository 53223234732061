@import 'Style/colors';

.fc-autocomplete {
  display: block;
  font-size: 16px;

  &__wrapper {
    width: 100%;
    position: relative;
  }

  &-field {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;
    position: relative;
    z-index: 2;

    &__placeholder {
      color: @f-color-service;
    }

    &__actions {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    &__arrow {
      margin-left: 12px;
      margin-top: -2px;

      svg {
        transition: transform 250ms ease-in-out;
      }
    }

    &__search {
      flex-grow: 1;
      flex-basis: 5px;
      min-width: 20px;
      flex-shrink: 1;
      font: inherit;
      font-weight: 400;
    }

    &__remove {
      margin-left: 5px;

      .fc-icon {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    border: 1px solid @f-color-border--dark;
    border-radius: 4px;
    z-index: 1;
  }

  &-result {
    flex-shrink: 0;
    flex-grow: 1;
    width: 0;

    &__content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &--valid {
    .fc-autocomplete__bg {
      border-color: @f-color-success;
    }
  }

  &--invalid {
    .fc-autocomplete__bg {
      border-color: @f-color-danger;
    }
  }

  &--disabled {
    .fc-autocomplete__bg {
      background-color: #f2f5f6;
    }
    .fc-autocomplete-field {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.fc-autocomplete-dropdown {
  font-size: 16px;
  .fc-popper__container {
    padding-left: 0;
    padding-right: 0;
    padding-top: 18px;
    padding-bottom: 12px;
  }
  &__scroll {
    max-height: 250px;
  }
  &__search {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;

    .fc-input {
      &__wrapper {
        height: 36px;
        font-size: 14px;
      }
    }
  }
  &__close {
    display: none;
  }

  .fc-autocomplete-list {
    &__empty {
      font-weight: 400;
      padding: 10px 20px;
      color: @f-color-service;
    }
  }

  .fc-list-item {
    padding-right: 20px;

    b {
      font-weight: bold;
    }
  }

  .os-scrollbar {
    &-vertical {
      right: 14px;
    }
  }

  &[data-view='modal'] {
    .fc-popper {
      &__container {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 32px !important;
      }
      &__content {
        padding-top: 8px;
      }
    }

    .fc-autocomplete-dropdown {
      &__scroll {
        height: auto;
      }
      &__search {
        padding-left: 20px;
        padding-right: 20px;
      }
      &__close {
        display: block;
        margin-top: 20px;
      }

    }

    .fc-autocomplete-list__empty {
      padding-bottom: 0;
    }
  }
}

// Селект открыт
.fc-autocomplete--active {
  .fc-autocomplete-field {
    &__arrow {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  .fc-autocomplete__bg {
    border-color: @f-color-primary;
    box-shadow: 0 0 5px rgb(63 101 241 / 50%);
  }
}
