@import "Style/colors";
@import "Style/breakpoints";

@keyframes sidesheet-content-appears {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes sidesheet-content-disappears {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

.sidesheet {
  .foquz-dialog {
    // определяет ширину модального окна
    &__container {
      max-width: 100%;
      margin: 0;
      height: 100%;

      width: var(--foquz-sidesheet-width);
      padding-left: var(--foquz-sidesheet-offset);
      margin-left: var(--foquz-sidesheet-margin);
    }

    // блок контента
    &__content {
      height: 100%;
      border-radius: 0;
      display: flex;
      flex-direction: column;

      transform: translateX(100%);

      box-shadow: 0px 5px 20px rgba(115, 128, 141, 0.4);
    }

    &__close {
      position: absolute;
      left: -45px;
      top: var(--foquz-sidesheet-remove-button-top);
      width: 33px;
      height: 33px;
      border-radius: 50%;
      color: white;
      border: 2px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;

      &,
      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }

    &__header {
      flex-shrink: 0;
      padding-left: 0;
      padding-right: 0;
    }

    &__title {
      font-size: 22px;
      font-weight: 900;
    }

    &__body {
      flex-grow: 1;
      overflow: hidden;
      padding: 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    &__scroll {
      height: 100%;

      & > .os-scrollbar-vertical {
        transform: translateX(-8px);
        z-index: 100;
      }
    }

    &__footer {
      height: 80px;
      border-radius: 0;
      flex-shrink: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  // анимация появления
  &.showing {
    .foquz-dialog__content {
      animation-name: sidesheet-content-appears;
      animation-duration: calc(var(--foquz-dialog-animation-duration) - 100ms);
      animation-delay: 100ms;
      animation-fill-mode: forwards;
    }
  }

  &.shown {
    .foquz-dialog__content {
      transform: translateX(0%);
    }
  }

  &.hiding {
    animation-delay: 100ms;

    .foquz-dialog__content {
      animation-name: sidesheet-content-disappears;
      animation-duration: calc(var(--foquz-dialog-animation-duration) - 100ms);
      animation-fill-mode: forwards;
    }
  }

  &.hidden {
    .foquz-dialog__content {
      transform: translateX(100%);
    }
  }

  // ограничение по ширине
  .container,
  .foquz-dialog__actions {
    padding-right: 30px;
    padding-left: 30px;
    margin-left: 0;
    max-width: 100%;
  }

  &--narrow {
    --foquz-sidesheet-offset: calc(100% - 610px);

    @media screen and (min-width: 1600px) {
      --foquz-sidesheet-offset: calc(1600px - 610px);
    }

    .container,
    .foquz-dialog__actions {
      max-width: 610px;
    }
  }

  &__status-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background-color: currentColor;
    z-index: 10;
  }

  // градиент под табами
  .nav {
    &:after {
      display: none;
    }
  }

  .mobile-and-tablet({
    .foquz-dialog {
      &__container {
        width: 100%;
        padding-left: 0;
        margin-left: 0;
      }

      &__title {
        font-size: 19px;
      }

      &__close {
        top: 0;
        right: 0;
        left: auto;
        height: 50px;
        width: 50px;
        //background: #dfe3e5;
        border-radius: 0;
        border: none;
        z-index: 10;
        color: @f-color-service;
      }
    }

    .foquz-dialog__tabs, .foquz-dialog__header {
      padding-right: 50px;
      min-height: 50px;
    }

    .foquz-dialog__tabs {
      .nav-item {
        margin: 0;
        height: 50px;
      }
    }

    .container,
    .foquz-dialog__actions {
      padding-right: 15px;
      padding-left: 15px;
      margin-left: 0;
      max-width: 100%;

      .full-width {
        margin-left: -15px;
        margin-right: -15px;
      }
    }

    .foquz-dialog__scroll {
      & >  .os-scrollbar {

        display: none;
      }
    }
   
  });

  .only-mobile({
    .foquz-dialog__actions {
      .f-btn {
        flex-grow: 1;
      }
    }
    .foquz-dialog__header {
      padding-top: 28px;
    }
    &__close {
      top: 8px;
    }
  });
}
