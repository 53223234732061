@import 'Style/colors';
.fc-user {
  font-size: 12px;
  font-weight: 500;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__avatar {
    margin-right: 12px;
  }
}
