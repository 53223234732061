@import "Style/breakpoints";
@import "Style/colors";

.move-poll-to-folder-dialog {
  .only-mobile({
    .foquz-dialog__body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
    }
    .folder-picker {
      flex-grow: 1;
      margin-bottom: 12px;
    }

    .os-scrollbar {
      display: none;
    }

    .survey-list__move-modal-dialog-folder-picker-list {
      margin-left: 0;
    }

  });
}
