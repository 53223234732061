@import 'Style/colors';

.fc-period-picker {
  display: block;
  width: 280px;

  &--inline {
    display: inline-block;
    width: auto;
  }

  &__controls {
    display: flex;
    padding: 20px;
  }

  &__actions {
    background: #fbfbfb;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  &__ranges {
    margin-left: 20px;
  }

  &__range {
    padding: 10px 12px;
    font-size: 16px;
    color: @f-color-primary;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;

    &.active {
      color: @f-color-text;
    }
  }
}

.fc-popper.fc-period-picker-popper {
  .fc-popper__container {
    padding: 0;
  }

  &[data-view="modal"] {
    .fc-popper__container {
      padding: 0;
    }
    .fc-period-calendar {
      width: 100%;
    }
    .fc-period-picker {
      &__calendar {
        width: 100%;
      }

      &__controls {
        flex-direction: column-reverse;
        padding-top: 5px;
      }
      &__ranges {
        margin-left: 0;
        margin-bottom: 20px;
      }
      &__range {
        padding: 0 0 10px;
        font-size: 14px;
      }
    }

  }
}
