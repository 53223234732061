@import 'Style/colors';

.fc-list {
  display: block;
  &-item {
    min-height: 30px;
    display: flex;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 20px;
    padding-left: calc(var(--fc-list-item-level, 0) * 10px + 20px);
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    color: @f-color-primary;

    &:hover {
      background: #F1F5F6;
    }

    &--disabled {
      color: @f-color-service;
      cursor: default;

      &:hover {
        background: transparent;
      }
    }

    &--selected {
      color: @f-color-text;
    }

    &--category {
      color: @f-color-service;
      cursor: default;
      font-size: 14px;
      font-weight: 700;

      &:hover {
        background: transparent;
      }
    }
  }
  &-checkbox {
    font-size: 14px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .fc-check {
      &__label {
        color: @f-color-primary;
      }

      &--checked {
        &__label {
          color: @f-color-text;
        }
      }

      &--disabled {

        &__label {
          color: @f-color-service;
        }
      }
    }
  }

  &--active-categories {
    .fc-list-item--category {
      cursor: pointer;
      &.fc-list-item--selected {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
}
