.f-radio-group {
  display: block;

  .hat-radio-group {
    margin-bottom: 0;
    display: flex;
    height: auto;
    min-height: 48px;

    border-radius: 4px;
    position: relative;
    overflow: hidden;
    border: none;

    &:after {
      content: '';
      border: 1px solid #cfd8dc;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      pointer-events: none;
    }

    &__radio {
      border: 1px solid #cfd8dc;
      margin-left: -1px;
      margin-top: -1px;
      width: auto;
      flex-grow: 1;
    }
  }
}
