@import 'Style/colors';

.theme() {
  danger: @button-danger-color, @button-danger-bg, @button-danger-hover,
    @button-danger-text, @button-danger-text-hover;
  success: @button-success-color, @button-success-bg, @button-success-hover,
    @button-success-text, @button-success-text-hover;
  primary: @button-primary-color, @button-primary-bg, @button-primary-hover,
    @button-primary-text, @button-primary-text-hover;
  secondary: @button-secondary-color, @button-secondary-bg,
    @button-secondary-hover, @button-secondary-text,
    @button-secondary-text-hover;
  white: @button-white-color, @button-white-bg, @button-white-hover,
    @button-white-text, @button-white-text-hover;
  black: @button-black-color, @button-black-bg, @button-black-hover,
    @button-black-text, @button-black-text-hover;
}

.fc-btn {
  &-b {
    each(.theme(), .(@v, @k) {
      &--@{k} {
        color: extract(@v, 1);
        background-color: extract(@v, 2);

        .hover &, &:hover {
          color: extract(@v, 1);
          background-color: extract(@v, 3);
        }

        &[disabled], &.disabled {
          &:hover {
            color: extract(@v, 1);
            background-color: extract(@v, 2);
          }
          .hover & {
            color: extract(@v, 1);
            background-color: extract(@v, 3);
          }
        }

        &.fc-btn-b--mode_text,
        &.fc-btn-b--inverse {
          background-color: transparent;
          color: extract(@v, 4);

          &:hover {
            background-color: transparent;
            color: extract(@v, 5);
          }

          &[disabled], &.disabled {
            background-color: transparent;
            color: extract(@v, 4);
          }
        }
      }
    });

    &[disabled],
    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    &__pending {
      color: inherit;
    }
  }
}
