@import 'Style/colors';
@import 'Style/breakpoints';

.complex-table {
  --complex-table-side-offset: 30px;


  position: relative;
  display: block;
  width: 100%;



  table {
    min-width: 100%;
    margin-bottom: 20px;
  }

  &__head {
    width: 100%;
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 5;

    table {
      margin-bottom: 0;
    }

    thead {
      position: relative;
    }
  }

  &__body {
    width: 100%;
    z-index: 3;
  }
  &__controls {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;

    .complex-table__control {
      width: 34px;
      height: 100%;
      position: relative;

      &--left {
        left: -25px;

        .svg-icon {
          margin-left: -2px;
        }
      }

      &--right {
        right: -25px;

        .svg-icon {
          margin-right: -2px;
        }
      }

      .mobile-and-tablet({
        &--left {
          left: -8px;
        }
        &--right {
          right: -8px;
        }
      });
    }

    button {
      pointer-events: all;
      position: sticky;
      top: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      padding: 0;
      z-index: 200;
      background: #ffffff;
      box-shadow: 0 5px 15px rgba(46, 47, 49, 0.3);
      border: none;
      border-radius: 50%;
      transition: opacity 200ms ease-in-out;

      color: @f-color-service;

      .svg-icon {
        width: 8px;
        height: 12px;
      }
    }
  }

  &__body.native-scrollbar--x-start + .complex-table__controls {
    .complex-table__control--left {
      button {
        //opacity: 0.4;
        pointer-events: none;
      }
    }
  }

  &__body.native-scrollbar--x-end + .complex-table__controls {
    .complex-table__control--right {
      button {
        //opacity: 0.4;
        pointer-events: none;
      }
    }
  }

  &__body:not(.native-scrollbar--has-x) + .complex-table__controls {
    display: none;
  }

  &--sticky-head {
    .complex-table__body > table > thead th {
      position: sticky;
      top: 0px;
      background: white;
      z-index: 5;
      padding-top: 22px;
    }
  }

  &--fixed-head {
    .complex-table__body, .complex-table__head {
      & > table > thead {
        th:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          height: 22px;
          z-index: 10;
          background: linear-gradient(180deg, rgba(115, 128, 141, 0.15) 0%, rgba(115, 128, 141, 0) 100%);
        }

        th:first-child {
          &:before {
            content: '';
            position: absolute;
            left: calc(-1 * var(--complex-table-side-offset));
            width: var(--complex-table-side-offset);
            top: 0;
            bottom: 0;
            background: white;
          }
          &:after {
            left: calc(-1 * var(--complex-table-side-offset));
          }
        }

        th:last-child {
          &:before {
            content: '';
            position: absolute;
            right: calc(-1 * var(--complex-table-side-offset));
            width: var(--complex-table-side-offset);
            top: 0;
            bottom: 0;
            background: white;
          }
          &:after {
            right: calc(-1 * var(--complex-table-side-offset));
          }
        }
      }
    }



  }
}
