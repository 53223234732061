@import 'Style/colors';
.fc-scrollable-table {
  display: block;

  &--sticky-header {
    .fc-table__table {
      thead {
        th {
          position: sticky;
          top: 0;
        }
      }
    }
  }

  &--fixed {
    .fc-scrollable-table__table {
      thead {
        th {
          background: white;
        }
        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0px;
          right: 0px;
          height: 22px;
          background: linear-gradient(
            180deg,
            rgba(115, 128, 141, 0.15) 0%,
            rgba(115, 128, 141, 0) 100%
          );
        }
      }
    }
  }

  &__wrapper {
    position: relative;
  }

  &-control {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;

    &--left {
      left: -17px;
    }

    &--right {
      right: -17px;
    }

    .fc-btn {
      position: sticky;
      top: 20px;
      bottom: 20px;
    }
  }
}
