@import "Style/colors";

.fc-check {
  display: block;
  cursor: pointer;

  label {
    display: flex;
    cursor: pointer;
  }

  &__input {
    display: block !important;
    opacity: 0;
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }

  &__box {
    display: block;
    width: 24px;
    height: 24px;
    border: 1px solid @f-color-border--dark;
    margin-right: 12px;
    border-radius: 6px;
    position: relative;
    flex-shrink: 0;
  }

  &__marker {
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  &__label {
    color: @f-color-service;
    min-height: 24px;
    display: flex;
    align-items: center;
  }

  &--checkbox {
    .fc-check__marker {
      width: 10px;
      height: 8px;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &--radio {
    .fc-check__box {
      border-radius: 50%;
    }
    .fc-check__marker {
      width: 8px;
      height: 8px;
      background-color: @f-color-primary;
      border-radius: 50%;
    }
  }

  &.fc-check--checked {
    .fc-check__box {
      .fc-check__marker {
        opacity: 1;
      }
    }
    .fc-check__label {
      color: @f-color-text;
    }
  }

  &__input:checked ~ &__box {
    .fc-check__marker {
      opacity: 1;
    }
  }

  &__input:checked ~ &__label {
    color: @f-color-text;
  }

  &__input:focus ~ &__box {
    box-shadow: 0px 0px 5px rgba(63, 101, 241, 0.5);
    border-color: @f-color-primary;
  }

  &--disabled {
    opacity: 0.6;
  }

  &--sm {
    .fc-check__box {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }

    .fc-check__label {
      min-height: 19px;
      font-size: 12px;
    }

    &.fc-check--radio {
      .fc-check__marker {
        width: 6px;
        height: 6px;
      }
    }
  }

  &--checkbox.fc-check--partial {
    .fc-check__marker {
      opacity: 1;
      width: 8px;
      height: 8px;
      background: #3f65f1;
      box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
      border-radius: 2px;
    }
  }
}
