.validationMessage {
  display: none;
}

.select2-selection {
  .folder-option {
    overflow: hidden;
  }
}
.password-inner-input-group .form-control.is-valid {
  background-position: center right calc(2.5em + 0.1875rem);
}

.ui-tooltip {
  display: none;
}