.question-icon-set() {
  rate: 24, 24;
  variants: 21, 20;
  text: 20, 17;
  date: 22, 23;
  address: 18, 24;
  file: 20, 20;
  quiz: 20, 19;
  priority: 23, 20;
  media-variants: 21, 19;
  gallery: 24, 24;
  smile: 22, 22;
  nps: 24, 14;
  matrix: 18, 18;
  diff: 22, 16;
  stars: 24, 24;
  inter: 24, 20;
}
.f-icon-question-type {
  each(.question-icon-set(), .(@v, @k) {
    &--@{k} {
      svg {
        width: 1px * extract(@v, 1);
        height: 1px * extract(@v, 2);
      }
    }
  });
}
