.f-btn-add {
  height: 48px;
  border-radius: 4px;
  background: @f-color-bg--body;
  color: @f-color-primary;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  padding-left: 68px;
  padding-right: 20px;
  &:hover,
  &:not([disabled]):hover {
    color: @f-color-primary;
  }
  &:before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 1C6 0.447716 6.44772 0 7 0C7.55228 0 8 0.447715 8 1V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V1Z' fill='white'/%3E%3Cpath d='M1 8C0.447716 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H1Z' fill='white'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: 14px;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    height: 48px;
    width: 48px;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @f-color-primary;
  }
}

.button-add {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 0 20px;
  position: relative;
  z-index: 1;

  border: 0;
  border-radius: 4px;
  background-color: #3f65f1;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);

  &:hover {
    background-color: #3553c1;
    color: #fff;
  }

  &[disabled] {
    opacity: 0.5;
  }

  &--with-dropdown {
    padding-right: 60px;

    &:before {
      .icon-arrow-bottom2(#fff);

      content: '';

      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(50%, -50%);
      z-index: 1;

      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &:after {
      content: '';

      display: block;
      position: absolute;
      width: 40px;
      height: 100%;
      top: 0;
      right: 0;

      background: #3a5cdc;

      transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    &:hover {
      &:after {
        background-color: #3553c1;
      }
    }
  }
}
