.foquz-chars-counter {
  position: relative;
  display: block;

  &__value {
    position: absolute;
    bottom: 18px;
    right: 10px;

    font-size: 11px;

    color: #73808d;
    opacity: 0.5;
  }

  textarea + &__value {
    bottom: 10px;
    right: 10px;
  }

  input:focus + &__value,
  textarea:focus + &__value {
    opacity: 1;
  }

  .is-valid + &__value {
    display: none;
  }

  &.foquz-chars-counter--hide-counter {
    .foquz-chars-counter__value {
      display: none;
    }
  }
}
