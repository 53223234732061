@import 'Style/colors';

.fc-expander {
  display: inline;

  button {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .fc-icon {
    transition: transform 250ms ease-in-out;
  }

  &--open {
    .fc-icon {
      transform: rotate(-180deg);
    }
  }
}
