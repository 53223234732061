@import 'Style/colors';

.f-icon {
  display: inline-block;
  vertical-align: baseline;
  position: relative;
  overflow: hidden;

  flex-shrink: 0;

  width: 24px;
  height: 24px;

  color: @f-color-service;

  svg {
    color: inherit;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }

  // SIZES
  &-lg {
    width: 30px;
    height: 30px;
  }

  &-md {
    width: 16px;
    height: 16px;
  }

  &-sm {
    width: 12px;
    height: 12px;
  }
}

.f-icon-button {
  padding: 0;
  &,
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    border: none;
    outline: none;
    box-shadow: none;
    background: transparent;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}



.f-icon-arrow {
  &--top {
    width: 14px;
    height: 8px;
  }
}

.f-icon-dash {
  display: inline-block;
  width: 10px;
  height: 2px;
  background: @f-color-light;
  font-size: 0;
  line-height: 1;
  margin-bottom: 2px;
}

.f-exclamation-mark {
  width: 14px;
  height: 14px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z' fill='%23F96261'/%3E%3Cpath d='M6 4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4V7C8 7.55228 7.55228 8 7 8C6.44772 8 6 7.55228 6 7V4Z' fill='white'/%3E%3Cpath d='M6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10Z' fill='white'/%3E%3C/svg%3E");


  &--tariff {
    display: none;
  }

  .body.body--warning_tariff &--tariff {
    display: block;
  }
}

.f-icon {
  &-primary {
    color: @f-color-primary;
  }
  &-success {
    color: @f-color-success;
  }
  &-danger {
    color: @f-color-danger;
  }
  &-light {
    color: @f-color-light;
  }
  &-white {
    color: #fff;
  }
}

@import './types/question-type';
@import './types/channel';
@import './types/view-mode.less';
@import './types/viewport-mode.less';
@import './types/site-section.less';
@import './types/type.less';
@import './types/chart.less';
@import './types/social.less';
@import './types/other.less';
