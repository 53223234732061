.custom-fancybox__not-supported {
  overflow: visible;
  padding: 20px !important;
  height: 84vh !important;
  width: 100%;
  max-width: 1190px !important;
  max-height: 678px !important;
  background-color: rgba(242, 243, 245, 1) !important;

  .fancybox-close-small {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #ccc;
    cursor: pointer;
    opacity: 0.8;
    padding: 8px;
    position: absolute;
    right: -12px;
    top: -44px;
    z-index: 401;
  }
  .fancybox-close-small:hover {
    color: #fff;
    opacity: 1;
  }
}

.custom-fancybox__not-supported-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.1;
  color: black;
  text-align: center;
}

.custom-fancybox__not-supported-link {
  text-decoration: none !important;
  color: #000;
  background-color: rgba(255, 255, 255, 1);
  transition: all 0.3s !important;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  line-height: 1.1;
  border-radius: 18px;
  height: 36px;
  padding-left: 17px;
  padding-right: 15px;
}

.custom-fancybox__not-supported-link:hover {
  opacity: 0.8;
}

.custom-fancybox__not-supported-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.custom-fancybox .fancybox__nav .f-button svg {
  stroke: none;
  fill: white;
  stroke-width: 0;
}


@media screen and (max-width: 679px) {
  .custom-fancybox__not-supported{
    max-width: 320px !important;
    max-height: 320px !important;
  }
  .custom-fancybox-audio .fancybox__content {
    aspect-ratio: 10/10 !important;
  }

  .custom-fancybox__not-supported-inner {
    max-width: 280px !important;
  }

  .custom-fancybox__not-supported-title {
    font-size: 14px;
  }
  .has-youtube .fancybox__content,
  .has-vimeo .fancybox__content,
  .has-html5video .fancybox__content:not(.custom-fancybox__not-supported) {
    height: auto !important;
  }

  .is-compact .fancybox__nav {
    display: none !important;
  }
}
