.icon-chart-set() {
  column: 14, 16;
  pie: 16, 16;
}
.f-icon-chart {
  each(.icon-chart-set(), .(@v, @k) {
    &--@{k} {
      svg {
        width: 1px * extract(@v, 1);
        height: 1px * extract(@v, 2);
      }
    }
  });

  &.active {
    color: @f-color-blue-2;
  }
}
