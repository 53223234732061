.fc-dropdown-btn {
  display: inline-flex;

  &__button {
    .fc-btn-b {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  &__toggler {
    .fc-btn-b {
      padding-left: 0;
      padding-right: 0;
      width: 40px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}
