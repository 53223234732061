@import 'Style/colors';

.default-theme-buttons() {
  danger: @f-btn-bg--danger, @f-btn-color--danger, @f-btn-hover--danger;
  success: @f-btn-bg--success, @f-btn-color--success, @f-btn-hover--success;
  primary: @f-btn-bg--primary, @f-btn-color--primary, @f-btn-hover--primary;
  light: @f-color-bg--service, @f-color-text, @f-btn-bg--default;
  white: white, @f-color-text, #e7e7e7;
}

a.f-btn:focus {
  text-decoration: none;
  color: @f-btn-color--default;
}
.f-btn {
  font-size: 13px;
  color: @f-btn-color--default;
  background-color: @f-btn-bg--default;

  border: none;
  box-shadow: none;
  outline: none;

  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

  &.is-invalid {
    box-shadow: 0 0 0 1px @f-color-danger;
  }

  &:not([disabled]):not(.disabled):hover {
    background-color: @f-btn-hover--default;
    color: @f-btn-color--default;
  }

  &[disabled], &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &-prepend-section, &-append-section {
    background-color: @f-btn-hover--default;
  }

  each(.default-theme-buttons(), .(@v, @k) {
    &-@{k} {
      color: extract(@v, 2);
      background-color: extract(@v, 1);

      &:not([disabled]):not(.disabled):hover {
        background-color: extract(@v, 3);
        color: extract(@v, 2);
      }

      .f-icon {
        color: inherit;
      }

      .f-btn-prepend-section, .f-btn-append-section {
        background-color: extract(@v, 3);
      }
    }

    &-@{k}-outline {
      background-color: transparent!important;
      color: extract(@v, 1);
      border: 1px solid extract(@v, 1)!important;

      &:not([disabled]):not(.disabled):hover {
        background-color: transparent!important;
        color: extract(@v, 1);
        border: 1px solid extract(@v, 1)!important;
      }

      .f-icon {
        color: inherit;
      }

      .f-btn-prepend-section, .f-btn-append-section {
        background-color: extract(@v, 1);
      }
    }
  });




  &-text {
    background-color: transparent;
    color: @f-color-text;

    &:hover,
    &:not([disabled]):not(.disabled):hover,
    &[disabled], &.disabled {
      background-color: transparent;
      color: @f-color-text;
    }

    &.f-btn-success {
      color: @f-color-success;

      &:not([disabled]):not(.disabled):hover {
        color: @f-color-success;
      }
    }

    &.f-btn-service {
      color: @f-color-service;

      &:not([disabled]):not(.disabled):hover {
        color: @f-color-service;
      }
    }

    &.f-btn-primary {
      color: @f-color-primary;

      &:not([disabled]):not(.disabled):hover {
        color: @f-color-primary;
      }
    }
  }

  &-link {
    background-color: transparent;
    color: @f-color-link;
    padding: 0;
    &:hover,
    &:not([disabled]):not(.disabled):hover {
      background-color: transparent;
    }

    &:not([disabled]):not(.disabled):hover {
      color: @f-color-link--hover;
    }
    &.-mt-15p {
      margin-top: -15px;
    }
    &.-mt-10p {
      margin-top: -10px;
    }
  }
}
