@foquz-number-input-control-size: 14px;
@foquz-number-input-control-size-min: 10px;

.foquz-number-input {
  display: inline-flex;
  align-items: center;

  height: 48px;
  padding: 0 8px;

  border: 1px solid var(--foquz-border-color, #cfd8dc);
  border-radius: 4px;

  &__control {
    position: relative;

    width: 20px;
    height: 20px;
    padding: 0;

    font-size: 24px;

    color: var(--foquz-service-color, #73808d);
    background: none;
    border: none;
    outline: none;
    box-shadow: none;

    cursor: pointer;

    &:focus,
    &:active,
    &:hover {
      background: none;
      border: none;
      outline: none;
      box-shadow: none;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    &:before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 14px;
      height: 2px;

      background: currentColor;
    }

    &--increment {
      &:after {
        content: '';

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 2px;
        height: 14px;

        background: currentColor;
      }
    }
  }

  &__field {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
    margin: 0 6px;

    text-align: center;

    font-size: inherit;
    font-family: inherit;

    color: inherit;
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  &.dense {
    height: auto;
    border: none;

    .foquz-number-input {
      &__control {
        color: currentColor;
        background-color: var(--foquz-light-color, #eceff1);
        border-radius: 50%;

        &:before {
          width: 10px;
        }
        &:after {
          height: 10px;
        }
      }
      &__field {
        width: auto;
        min-width: 20px;
        max-width: none;
      }
    }
  }
}
