@import 'Style/colors';

// TODO remove
.icon-arrow-bottom2(@bg-color) {
  @svg-content: escape(
    '
		<svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.999996 1.96875L7 7.96875L13 1.96875" stroke="@{bg-color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
  	'
  );
  width: 14px;
  height: 9px;
  background-image: url('data:image/svg+xml,@{svg-content}');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.f-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  // white-space: nowrap;

  vertical-align: middle;

  height: 38px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;

  font: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;

  &:hover {
    text-decoration: none;
  }

  border-radius: 4px;

  cursor: pointer;

  &-text {
    padding: 0;
  }

  &-link {
    padding: 0;
  }

  &--wrap {
    white-space: normal;
  }

  .f-btn + & {
    margin-left: 10px;
  }
}

.f-btn-check {
  height: 48px;
  padding: 0 22px;
}

@import './themes/default.less';

@import './btn-sizes.less';
@import './btn-shapes.less';
@import './btn-parts.less';
@import './btn-group.less';
@import './btn-dropdown.less';
@import './btn-add.less';
@import './btn-badge.less';

@import './states/loading.less';
