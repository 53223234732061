@import 'Style/typography';
@import 'Style/colors';

.icon-checkbox(@bg-color) {
  @svg-content: escape(
    '
		<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.49373 0.758435C8.83831 0.413855 9.39698 0.413855 9.74156 0.758435C10.0861 1.10302 10.0861 1.66169 9.74156 2.00627L4.44745 7.30039C4.10287 7.64497 3.54419 7.64497 3.19961 7.30039L0.258435 4.35921C-0.0861451 4.01463 -0.0861451 3.45596 0.258435 3.11138C0.603015 2.7668 1.16169 2.7668 1.50627 3.11138L3.82353 5.42864L8.49373 0.758435Z" fill="@{bg-color}"/>
		</svg>
	'
  );
  width: 10px;
  height: 8px;
  background-image: url('data:image/svg+xml,@{svg-content}');
}

.f-check {
  display: block;
  &-input {
    position: absolute;
    display: none;
  }

  &-input ~ .f-check-label {
    cursor: pointer;
    color: #73808d;
    transition: color 0.185s ease-in-out;
    position: relative;
    padding-left: 24px;
    min-height: 24px;
    display: inline-block;
    font-size: @f-fs-2;
    line-height: 1.2;
    padding-top: 12px - (@f-fs-2 * 0.6);

    &:not(:empty) {
      padding-left: 34px;
    }
  }

  &-input ~ .f-check-label:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 0;
    width: 24px;
    height: 24px;
    transition: background 300ms ease-in-out;
    border: solid 1px #cfd8dc;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 6px !important;
  }

  &-input ~ .f-check-label:after {
    .icon-checkbox(#3f65f1);

    content: '';

    font-weight: 900;
    font-size: 8px;
    display: inline-block;
    opacity: 0;
    position: absolute;
    top: 8px;
    left: 7px;
  }

  &-input:checked ~ .f-check-label:after {
    opacity: 1;
  }
  &--checked .f-check-label:after {
    opacity: 1;
  }

  &-input:checked ~ .f-check-label {
    color: @f-color-text;
  }

  &-input:disabled ~ .f-check-label {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &--middle {
    .f-check-label {
      display: inline-flex;
      align-items: center;
      min-height: 24px;
      padding-top: 0;

      &:before {
        top: 50%;
        transform: translateY(-12px);
      }
      &:after {
        top: 50%;
        transform: translateY(-4px);
      }
    }
  }

  &.f-check--partially {
    .f-check-input:checked ~ .f-check-label {
      &:after {
        width: 8px;
        height: 8px;
        border-radius: 2px;
        left: 8px;
        background-image: none;
        background: @f-color-primary;
      }
    }
  }
}

.f-check-row {
  display: flex;
  align-items: flex-start;

  .f-check {
    margin-right: 30px;
  }
}

.f-check-custom {
  .f-check-input ~ .f-check-label:after,
  .f-check-input ~ .f-check-label:before {
    display: none;
  }
}

.form-radio {
  position: absolute;
  display: none;

  & ~ label {
    padding-left: 14px;
    cursor: pointer;
    color: #73808d;
    transition: color 0.185s ease-in-out;
  }

  & ~ label:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -3px;
    left: 0;
    width: 24px;
    height: 24px;

    border: solid 1px #cfd8dc;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 50%;
  }

  & ~ label:after {
    width: 8px;
    height: 8px;
    background-color: #000000;
    border-radius: 50%;
    content: '';

    font-weight: 900;
    font-size: 8px;
    display: inline-block;
    opacity: 0;
    transform: scale(0.5);
    position: absolute;
    top: 50%;
    margin-top: -4px;
    left: 8px;
    transition: all 0.185s ease-in-out;
  }

  &:checked ~ label:after {
    opacity: 1;
    transform: scale(1);
  }

  &:checked ~ label {
    color: #2e2f31;
  }

  &:disabled ~ label {
    opacity: 0.4;
  }
}

.f-radio {
  display: block;
  &-input {
    position: absolute;
    display: none;
  }

  &-input ~ .f-radio-label {
    cursor: pointer;
    color: #73808d;
    transition: color 0.185s ease-in-out;
    position: relative;
    padding-left: 24px;
    min-height: 24px;
    display: inline-block;
    font-size: @f-fs-2;
    line-height: 1.2;
    padding-top: 12px - (@f-fs-2 * 0.6);

    &:not(:empty) {
      padding-left: 34px;
    }
  }

  &-input ~ .f-radio-label:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 0;
    width: 24px;
    height: 24px;
    transition: background 300ms ease-in-out;
    border: solid 1px #cfd8dc;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 50%;
  }

  &-input ~ .f-radio-label:after {
    width: 8px;
    height: 8px;
    background-color: @f-color-primary;
    color: @f-color-service;
    border-radius: 50%;
    content: '';
    display: inline-block;
    opacity: 0;
    position: absolute;
    top: 8px;
    left: 8px;
    transition: all 0.185s ease-in-out;
  }

  &-input:checked ~ .f-radio-label:after {
    opacity: 1;
  }

  &-input:checked ~ .f-radio-label {
    color: @f-color-text;
  }

  &-input:disabled ~ .f-radio-label {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.f-radio-custom {
  .f-radio-label,
  .f-radio-input ~ .f-radio-label:not(:empty) {
    padding-left: 0;
  }
  .f-radio-input ~ .f-radio-label:after,
  .f-radio-input ~ .f-radio-label:before {
    display: none;
  }
}

.foquz-checkbox,
.foquz-radio {
  display: block;
  &.is-invalid {
  }
}

.foquz-check-control--fill {
  &.f-radio {
    .f-radio-input ~ .f-radio-label:before {
    }
  }
}

.foquz-check-control + .validation-feedback {
  padding-left: 34px;
}

.foquz-radio.sm {
  .f-radio-input ~ .f-radio-label {
    min-height: 18px;
    padding-top: 1.6px;
    font-size: 12px;
  }

  .f-radio-input ~ .f-radio-label:before {
    width: 18px;
    height: 18px;
  }

  .f-radio-input ~ .f-radio-label:after {
    width: 6px;
    height: 6px;
    top: 6px;
    left: 6px;
  }

  .f-radio-input ~ .f-radio-label:not(:empty) {
    padding-left: 28px;
  }
}


.foquz-checkbox.sm {

  .f-check-input ~ .f-check-label {
    min-height: 18px;
    padding-top: 1.6px;
    font-size: 12px;
  }

  .f-check-input ~ .f-check-label:before {
    width: 18px;
    height: 18px;
  }


  .f-check-input ~ .f-check-label:after {
    // width: 6px;
    // height: 6px;
    top: 5px;
    left: 4.5px;
  }

  .f-check-input ~ .f-check-label:not(:empty) {
    padding-left: 28px;
  }
}
