@import 'Style/colors';

.fc-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  label {
    display: block;
  }

  &__text {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    vertical-align: baseline;
  }

  &__required {
    color: @f-color-danger;
    font-size: 20px;
    font-weight: 700;
    line-height: 0.5;
    vertical-align: middle;
  }

  &__hint {
    margin-left: 5px;
    vertical-align: baseline;
    position: relative;
    top: 2px;
  }

  &__optional {
    margin-left: auto;
    flex-shrink: 0;
    white-space: nowrap;
    font-size: 12px;
    color: @f-color-service;
  }

  &--style_sm {
    margin-bottom: 6px;

    .fc-label__text {
      font-weight: 500;
      color: @f-color-service;
      font-size: 14px;
    }
  }

  &--style_xs {
    margin-bottom: 6px;

    .fc-label__text {
      font-weight: 500;
      color: @f-color-service;
      font-size: 12px;
    }
  }
}
