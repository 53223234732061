.foquz-interval-slider {
  display: block;
  padding-left: 12px;
  padding-right: 12px;

  &--labels {
    padding: 0;
  }
}

.interval-slider {
  display: flex;
  align-items: center;
  padding-top: 22px;
  font-family: inherit!important;

  .ui-widget {
    font-family: inherit!important;
  }

  &__slider {
    flex-grow: 1;
  }

  &__boundary {
    &--min {
      margin-right: 12px;
    }
    &--max {
      margin-left: 12px;
    }
  }

  &__handle {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px !important;
    height: 24px !important;
    top: calc(6px / 2 - 24px / 2);

    &-indicator {
      margin-top: -50px;
      font-weight: bold;
      font-size: 14px;
      color: #3f65f1;
    }
  }
}
