:root {
  --f-color-primary: #3a5cdc;
  --f-color-primary--hover: #3553c1;
  --f-color-primary--contrast: #ffffff;
  --f-color-service: #73808d;
  --f-color-light: #a6b1bc;
  --f-color-text: #2e2f31;
  --f-color-danger: #f96261;
  --f-color-success: #37a74a;
  --f-color-yellow: #ffcc00;
  --f-color-blue: #2d99ff;
  --f-color-blue-2: #3f65f1;
  --f-color-violet: #8400ff;
  --f-color-teal: #16ceb9;
  --f-color-gold: #caad46;
  --f-color-mint: #24bbb6;
  --f-color-red: #f96261;
  --f-color-bg--body: #f5f6fa;
}
/* Цвета иконок */
/* Цвета кнопок */
