.fc-btn {
  &-b {
    &__icon {
      &.fc-icon--times {
        width: 14px;
        height: 14px;
      }
    }

    &__icon + .fc-btn-b__label {
      margin-left: 10px;
    }



    &--xl {
      .fc-btn-b__icon {
        width: 22px;
        height: 22px;
      }
    }
  }
}
