.foquz-success-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #ABEFB7;
  color: #37A74A;

  border-radius: inherit;
}

.foquz-success-message__cross {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

.foquz-success-message--error {
  .foquz-success-message {
      background: #FFDDDD;
      color: #f96261;
  }
} 
