@import 'Style/colors';
.fc-table {
  display: block;

  &__wrapper {
    position: relative;
  }

  &__table {
    table {
      min-width: 100%;
      border-collapse: collapse;
    }

    th, td {
      &[align="left"] {
        text-align: left;
      }
      &[align="center"] {
        text-align: center;
      }
      &[align="right"] {
        text-align: right;
      }
    }

    thead {
      position: relative;
      z-index: 1;

      th {
        font-size: 12px;
        font-weight: 700;
        padding: 10px 10px 12px;
        border-bottom: 2px solid @f-color-border;
      }
    }

    td {
      padding: 10px;
      border-bottom: 1px solid @f-color-border;
    }
  }

  &--sticky-header {
    .fc-table__table {
      thead {
        th {
          position: sticky;
          top: 0;
        }
      }
    }
  }

  &--fixed {
    .fc-table__table {
      thead {
        th {
          background: white;

          &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0px;
            right: 0px;
            height: 22px;
            background: linear-gradient(
              180deg,
              rgba(115, 128, 141, 0.15) 0%,
              rgba(115, 128, 141, 0) 100%
            );
            z-index: 0;
          }

          &:first-child {
            &:after {
              left: -20px;
            }
          }

          &:last-child {
            &:after {
              right: -20px;
            }
          }
        }

      }
    }
  }

  &__bottom {
    height: 10px;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }
}
