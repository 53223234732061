.variant-text-field {
  &__text * {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .hat-radio-group {
    border-radius: 0 !important;
    height: 48px !important;
  }
  .hat-radio-group:after {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .hat-radio-group__radio:last-child {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
    border-right: 0!important;
  }
  .form-control {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height: 100%;
  }
  &__variant {
    height: 48px;
    flex-grow: 1;
  }
  &__text {
    height: 48px;
    z-index: 1;
    margin-left: -1px;
    flex-grow: 100;
  }
}