@import "Style/colors";
@import "@/less/vars/breakpoints";

.dialogs-container {
  position: relative;
  z-index: var(--foquz-dialog-z-index);

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--foquz-dialog-z-index);
  }

  &__remove-all {
    position: absolute;
    top: var(--foquz-sidesheet-remove-all-button-top);
    left: var(--foquz-sidesheet-remove-all-button-left);
    transform: translateX(-100%);
    z-index: 2;

    height: 33px;
    border: 2px solid;
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: transparent;
    padding: 0 15px;

    .svg-icon {
      margin-left: 6px;
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    &[data-last]:not([data-single]) {
      .foquz-dialog__content {
        margin-left: var(--foquz-sidesheet-shift);
        width: calc(100% - var(--foquz-sidesheet-shift));
      }
    }
    &:not([data-last]):not([data-single]) {
      .foquz-dialog__close {
        display: none;
      }
      .foquz-dialog__content {
        filter: blur(2px);
      }
    }
  }

  .bootstrap-lg-down({
    &__remove-all {
      display: none;
    }
  });

  .bootstrap-sm-down({
    &__item {
      &[data-last]:not([data-single]) {
        .foquz-dialog__content {
          margin-left: 0;
          width: 100%;
        }
      }
      &:not([data-last]):not([data-single]) {
        .foquz-dialog__close {
          display: none;
        }
        .foquz-dialog__content {
          filter: none;
        }
      }
    }
  });
}
