@import 'Style/colors';
@import 'Style/breakpoints';

.fc-img-preview {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 94px;
  height: 94px;

  border-radius: 8px;
  background-color: #eceff1;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: inherit;
    background-position: center;
    background-size: cover;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__remove {
    position: absolute;
    top: -6px;
    right: -6px;

    opacity: 0;
    transition: opacity 400ms;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;

    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 50%;

    background-color: black;
    color: white;

    font-size: 11px;

    cursor: pointer;
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover .fc-img-preview__remove {
    opacity: 1;
  }

  .mobile-and-tablet({
    &__remove {
      opacity: 1;
    }
  });
}
