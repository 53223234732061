.f-btn {
  &--square {
    width: 36px;
    padding: 0;
  }
  &--pill {
    border-radius: 60px;
  }
  &--round {
    border-radius: 50%;
    padding: 0;
    width: 36px;
  }
}
