@import 'Style/breakpoints';

.foquz-dialog__header {
  display: block;
  position: relative;
  padding: 35px 30px 8px 30px;

  &.foquz-dialog-header--empty {
    padding-top: 27px;
    padding-bottom: 0;
  }
}

.foquz-dialog__title {
  font-weight: 900;
  font-size: 19px;
  line-height: 1.1;
  color: #2e2f31;
  padding: 0;
  margin: 0;
}

.foquz-dialog__close {
  position: absolute;
  top: 16px;
  right: 15px;
}
