@import 'Style/breakpoints';

.popover-arrow(@bg-color) {
  @svg-content: escape(
    '
		<svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M20.7535 5.49998C21.7644 5.49999 21.9366 5.49999 20.7753 5.5C20.768 5.5 20.7607 5.49999 20.7535 5.49998C17.3188 5.49995 4.20265 5.49992 0.806732 5.49995C0.796259 5.49998 0.785763 5.5 0.775246 5.5C-0.345165 5.49998 -0.177311 5.49996 0.806732 5.49995C6.89597 5.48125 5.65919 1.90735e-06 10.7753 1.90735e-06C15.8941 1.90735e-06 14.5283 5.48723 20.7535 5.49998Z" fill="@{bg-color}"/>
		</svg>
	'
  );
  width: 22px;
  height: 6px;
  background-image: url('data:image/svg+xml,@{svg-content}');
}

.period-picker {
  margin-top: 10px;

  border: none;
  box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);
  border-radius: 9px;
  min-width: 325px;

  &:before {
    display: none;
  }

  &:after {
    display: none;
  }

  &:not(.show-ranges) {
    .ranges {
      display: none;
    }
  }

  .drp-calendar {
    padding: 20px !important;

    &.left {
      padding-right: 0;
    }
  }

  .drp-buttons {
    padding: 20px;

    background: #fbfbfb;
    border: none;
    border-radius: 0 0 9px 9px;

    .btn {
      margin: 0;
      padding: 8px 15px 8px 40px;

      font-size: 13px;
    }
  }

  .applyBtn {
    margin-left: 13px !important;
  }

  .drp-selected {
    display: none;
  }

  .drp-calendar {
    font-family: Roboto, sans-serif;

    thead {
      th {
        height: 42px;

        border-bottom: 1px solid rgba(207, 216, 220, 0.488168);
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 31px;
        color: #2e2f31;

        &.prev,
        &.next {
          line-height: 0;

          span {
            padding: 4px;

            border-color: #73808d;
          }
        }
      }

      tr:nth-child(2) {
        display: none;
      }
    }

    tbody {
      &:before {
        display: block;
        line-height: 8px;
        content: '\200C';
      }

      td {
        width: 32px;
        height: 32px;

        font-size: 16px;
        color: #2e2f31;
        font-weight: 400;

        &.off {
          color: #a6b1bc;
        }

        &:not(.off) {
          &.start-date,
          &.end-date {
            background: #3f65f1 !important;
            color: #fff;
          }

          &.in-range {
            background: #d9e0fc;
          }
        }

        &.active:hover {
          background: #3f65f1 !important;
        }
      }
    }
  }

  select {
    display: inline-block;
    width: 100%;
    height: 48px;
    padding: 16px 18px;

    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    border-color: #cfd8dc;
    line-height: 1.36;
    font-size: 16px;
    color: #2e2f31;
    font-weight: 400;

    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      background-color: #fff;
      outline: 0;
      color: #2e2f31;
      border: 1px solid #3f65f1;
      box-shadow: 0 0 5px rgba(63, 101, 241, 0.5);
    }
  }

  &.drop-up {
    .period-picker__arrow {
      transform: rotate(180deg);
      top: unset;
      bottom: -5px;
    }
  }

  &__arrow {
    .popover-arrow(#FFF) !important;

    position: absolute;
    top: -5px;
  }

  &__range-container {
    float: right !important;
    padding: 20px 20px 20px 8px;
  }

  &__range {
    padding: 10px 12px;

    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: #3f65f1;
    background: none;
    cursor: pointer;

    &:hover {
      background: #f1f5f6;
    }

    &--active {
      color: #000000;
    }
  }

  &__controls {
    display: flex;
  }

  &__end-input-group {
    margin-left: 21px;
  }

  .only-mobile({
    .period-picker__range-container {
      float: none!important;
      padding-bottom: 0px;
    }
    .drp-buttons {
      display: flex!important;
			.btn {
				padding-left: 15px;
				flex-grow: 1;
				&:before {
					display: none;

				}
			}
    }
    .drp-calendar {
      padding-left: 15px!important;
      padding-right: 15px!important;
    }

  });
}

date-picker,
period-picker {
  display: block;
}

// old
.icon-calendar(@bg-color) {
  @svg-content: escape(
    '
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M16 2V6" stroke="@{bg-color}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M8 2V6" stroke="@{bg-color}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M3 9H21" stroke="@{bg-color}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M19 4H5C3.895 4 3 4.895 3 6V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V6C21 4.895 20.105 4 19 4Z" stroke="@{bg-color}" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M6.99601 12C6.44402 12 5.99603 12.448 6.00003 13C6.00003 13.552 6.44802 14 7.00001 14C7.55201 14 8 13.552 8 13C8 12.448 7.55201 12 6.99601 12Z" fill="@{bg-color}"/>
			<path d="M11.996 12C11.444 12 10.996 12.448 11 13C11 13.552 11.448 14 12 14C12.552 14 13 13.552 13 13C13 12.448 12.552 12 11.996 12Z" fill="@{bg-color}"/>
			<path d="M16.996 12C16.444 12 15.996 12.448 16 13C16 13.552 16.448 14 17 14C17.552 14 18 13.552 18 13C18 12.448 17.552 12 16.996 12Z" fill="@{bg-color}"/>
			<path d="M6.99601 16C6.44402 16 5.99603 16.448 6.00003 17C6.00003 17.552 6.44802 18 7.00001 18C7.55201 18 8 17.552 8 17C8 16.448 7.55201 16 6.99601 16Z" fill="@{bg-color}"/>
			<path d="M11.996 16C11.444 16 10.996 16.448 11 17C11 17.552 11.448 18 12 18C12.552 18 13 17.552 13 17C13 16.448 12.552 16 11.996 16Z" fill="@{bg-color}"/>
		</svg>
	'
  );
  background-image: url('data:image/svg+xml,@{svg-content}');
}
.date-input-group {
  &__icon {
    .icon-calendar(#A6B1BC);
    position: absolute;
    width: 24px;
    height: 24px;
    top: 12px;
    right: 14px;

    z-index: 3;
  }
}
.dense-form-group {
  .date-input-group {
    display: flex;
    width: auto;
    .only-mobile({
			display: inline-flex;
		});
    &__icon {
      top: -6px;
      right: 0;
    }
  }
}

.date-input-group {
  position: relative;

  .form-control {
    padding-right: 40px;
    border-radius: 4px !important;
    background-image: none !important;
  }

  &.date-input-group--has-value {
    .form-control {
      padding-right: 68px;
    }
  }

  &__clear {
    position: absolute !important;
    right: 50px;
    top: 0;
    bottom: 2px;
    margin: auto;
    cursor: pointer;
  }

  input[type='date'] {
    padding-right: 0;
  }

  /* To remove default blue outline */
  input[type='date']:focus {
    outline: none;
  }

  /*  To remove cross sign */
  input[type='date']::-webkit-clear-button {
    display: none;
  }

  input[type='date']::-ms-clear {
    display: none;
  }

  /* To remove Spin Arrows */
  input[type='date']::-webkit-inner-spin-button {
    display: none;
  }

  /* To remove Drop down Arrow */
  input[type='date']::-webkit-calendar-picker-indicator {
    //display: none;
  }
}

.dense-form-group {
  .date-input-group {
    &__clear {
      right: 32px;
    }

    .form-control {
      padding-right: 35px;
    }
    &.date-input-group--has-value {
      .form-control {
        padding-right: 52px;
      }
    }
  }
}
