@import 'Style/colors';

.fc-select {
  display: block;
  font-size: 16px;

  &__wrapper {
    width: 100%;
    position: relative;
    display: block;
  }

  &-field {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;
    position: relative;
    z-index: 2;

    &__placeholder {
      color: @f-color-service;
      white-space: nowrap;
      overflow: hidden;
    }

    &__actions {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    &__arrow {
      margin-left: 12px;
      margin-top: -2px;

      svg {
        transition: transform 250ms ease-in-out;
      }
    }

    &__search {
      flex-grow: 1;
      flex-basis: 5px;
      min-width: 20px;
      flex-shrink: 1;
      font: inherit;
      font-weight: 400;
    }

    &__remove {
      margin-left: 5px;

      .fc-icon {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    border: 1px solid @f-color-border--dark;
    border-radius: 4px;
    z-index: 1;
  }

  &-result {
    flex-shrink: 0;
    flex-grow: 1;
    width: 0;

    &__content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

    }
  }

  &--valid {
    .fc-select__bg {
      border-color: @f-color-success;
    }
  }

  &--invalid {
    .fc-select__bg {
      border-color: @f-color-danger;
    }
  }

  &--disabled {
    .fc-select__bg {
      background-color: #f2f5f6;
    }
    .fc-select-field {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.fc-select-reader {
  font-size: 14px;
  &__result {
    display: inline-block;
  }
  &__result:not(:last-child):after {
    content: ', ';
    margin-left: -4px;
  }
}

.fc-select-dropdown {
  font-size: 16px;
  .fc-popper__container {
    padding-left: 0;
    padding-right: 0;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &__scroll {
    max-height: 250px;
  }
  &__search {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;

    .fc-input {
      &__wrapper {
        height: 36px;
        font-size: 14px;
      }
    }
  }

  .fc-select-list {
    &__empty {
      font-weight: 400;
      text-align: center;
      padding: 10px 20px;
      color: @f-color-service;
      white-space: nowrap;
    }
  }

  .fc-list-item {
    padding-right: 20px;
  }

  .os-scrollbar {
    &-vertical {
      right: 14px;
    }
  }

  &__actions {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 25px;

    .fc-btn {
      flex-grow: 1;
      flex-basis: 50%;
      margin: 0 10px;
    }
  }

  &[data-view='modal'] {
    .fc-select-dropdown {
      &__scroll {
        height: auto;
      }
      &__search {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .os-scrollbar-vertical {
      right: 0;
    }

    .fc-list {
      margin-top: -5px;

      .fc-list-checkbox {
        margin-top: 5px;
      }

      &-item {
        padding-left: 0;
        padding-right: 0;

        &:hover {
          background: transparent;
        }
      }
    }
  }
}

// Селект открыт
.fc-select--active {
  .fc-select-field {
    &__arrow {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  .fc-select__bg {
    border-color: @f-color-primary;
    box-shadow: 0 0 5px rgb(63 101 241 / 50%);
  }
}

.fc-select--multiple {
  .fc-select-field {
    padding: 5px 9px;
    flex-wrap: wrap;

    &__arrow {
      display: none;
    }

    &__placeholder {
      padding-left: 10px;
    }

    &__search {
      padding-left: 10px;
    }
  }
  .fc-select-result {
    min-height: 32px;
    flex-grow: 0;
    width: auto;
    max-width: 100%;
    display: flex;
    align-items: center;
    border-radius: 16px;
    padding: 6px;
    padding-left: 15px;
    background-color: @f-color-bg--service;
    margin: 3px;

    &__content {
      white-space: normal;
    }

    &__remove {
      .fc-btn-b {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: white;
        margin-left: 10px;
      }

      .fc-icon {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.fc-select--inline {
  display: inline-block;
  font-size: 14px;

  .fc-select__bg {
    border: none;
  }

  .fc-select-field {
    padding: 0;
    min-height: 0;
    width: auto;

    &__arrow {
      display: block;
    }

    &__search {
      padding-left: 5px;
    }
  }

  .fc-select-result {
    min-height: 20px;
    display: flex;
    align-items: center;

    &__content {
      font-weight: 500;
    }
  }

  &.fc-select--single {
    .fc-select-result {
      width: auto;
    }
    .fc-select-field__remove {
      margin-left: 10px;
      .fc-icon {
        width: 10px;
        height: 10px;
      }
    }
  }

  &.fc-select--multiple {
    .fc-select-field {
      margin-left: -6px;
    }
    .fc-select-field__placeholder {
      padding: 0 6px;
    }
    .fc-select-result {
      background-color: transparent;
      margin: 0;
      padding: 0px 6px 0;

      &__remove {
        .fc-icon {
          width: 10px;
          height: 10px;
        }
        .fc-btn-b {
          background-color: #eceff1;
        }
      }
    }
  }
}
