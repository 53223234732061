@import 'Style/colors';
@import 'Style/breakpoints';

.media-load-button {
  position: relative;

  flex-shrink: 0;
  display: block;

  width: 105px;
  height: 105px;

  background-color: #eceff1;

  &.sm {
    width: 94px;
    height: 94px;
  }

  &:hover {
    background-color: #c6cfd3;
  }

  &.dark {
    background-color: #cfd8dc;
  }

  border-radius: 8px;

  &__empty {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 5px;

    font-size: 10px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;

    color: #73808d;

    cursor: pointer;

    .svg-icon {
      &--clip {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__preview {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;

    border-radius: inherit;

    &_top {
      object-position: top;
    }

    &_center {
      object-position: center;
    }

    &_bottom {
      object-position: bottom;
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__remove {
    position: absolute;
    top: -6px;
    right: -6px;

    opacity: 0;
    transition: opacity 400ms;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;

    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 50%;

    background-color: black;
    color: white;

    font-size: 11px;

    cursor: pointer;
  }

  &:hover .media-load-button__remove {
    opacity: 1;
  }

  &.is-invalid {
    box-shadow: 0 0 0 1px @f-color-danger;
  }

  .mobile-and-tablet({
    &__remove {
      opacity: 1;
    }
  });

  &--logo {
    width: 140px;
    height: 48px;

    &.media-load-button--preview.media-load-button--transparent {
      background: url('/img/poll-design__logo-bg.png');
    }

    .media-load-button__empty {
      flex-direction: row;

      .f-icon {
        margin-right: 8px;
      }
    }

    .media-load-button__preview {
      top: 8px;
      left: 12px;
      width: calc(100% - 24px);
      height: calc(100% - 16px);
      object-fit: contain;
    }

    .media-load-button__remove {
      top: -10px;
      right: -10px;
    }
  }
}
