@bootstrap-xs-top: 575.98px;
@bootstrap-sm-bottom: 576px;
@bootstrap-sm-top: 767.98px;
@bootstrap-md-bottom: 768px;
@bootstrap-md-top: 991.98px;
@bootstrap-lg-bottom: 992px;
@bootstrap-lg-top: 1199.98px;
@bootstrap-xl-bottom: 1200px;
@bootstrap-xl-top: 1399.98px;
@bootstrap-xxl-bottom: 1400px;


// <576
.bootstrap-xs-down(@content) {
    @media screen and (max-width: @bootstrap-xs-top) {
        @content();
    }
}

// <768
.bootstrap-sm-down(@content) {
    @media screen and (max-width: @bootstrap-sm-top) {
        @content();
    }
}

// <992
.bootstrap-md-down(@content) {
    @media screen and (max-width: @bootstrap-md-top) {
        @content();
    }
}

// <1200
.bootstrap-lg-down(@content) {
    @media screen and (max-width: @bootstrap-lg-top) {
        @content();
    }
}

// <1400
.bootstrap-xl-down(@content) {
    @media screen and (max-width: @bootstrap-xl-top) {
        @content();
    }
}

// >=576
.bootstrap-sm-up(@content) {
    @media screen and (min-width: @bootstrap-sm-bottom) {
        @content();
    }
}

// >=768
.bootstrap-md-up(@content) {
    @media screen and (min-width: @bootstrap-md-bottom) {
        @content();
    }
}

// >=992
.bootstrap-lg-up(@content) {
    @media screen and (min-width: @bootstrap-lg-bottom) {
        @content();
    }
}

// >=1200
.bootstrap-xl-up(@content) {
    @media screen and (min-width: @bootstrap-xl-bottom) {
        @content();
    }
}

// >=1400
.bootstrap-xxl-up(@content) {
    @media screen and (min-width: @bootstrap-xxl-bottom) {
        @content();
    }
}