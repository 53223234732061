.icon-view-mode-sizes() {
  list: 18, 18;
  cards: 18, 18;
  kanban: 22, 18;
}


.f-icon-view-mode {
  color: @f-color-light;
  cursor: pointer;

  &:hover {
    color: @f-color-text;
  }

  &--active {
    color: @f-color-primary;

    &:hover {
      color: @f-color-primary--hover;
    }
  }

  each(.icon-view-mode-sizes(), .(@v, @k) {
    &--@{k} {
      svg {
        width: 1px * extract(@v, 1);
        height: 1px * extract(@v, 2);
      }
    }
  });
}
