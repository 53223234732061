@foquz-dnd-cover-primary: #3f65f1;
@foquz-dnd-cover-secondary: #9bb0fb;

.foquz-dnd-cover {
  display: none;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  color: @foquz-dnd-cover-primary;
  background: white;
  opacity: 0.95;

  border-radius: 9px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;

    border: 2px dashed @foquz-dnd-cover-secondary;
    border-radius: 6px;
  }

  &__content {
    padding: 20px;

    text-align: center;

    font-size: 15px;
  }

  &__text {
    margin-top: 24px;
  }

  &__limit {
    font-size: 12px!important;
  }

  svg {
    color: @foquz-dnd-cover-secondary;
  }

  &--sm {
    .foquz-dnd-cover__icon {
      svg {
        width: 68px;
        height: 68px;
      }
    }
  }

  &--xs {
    .foquz-dnd-cover__icon {
      svg {
        width: 48px;
        height: 48px;
      }
    }
    .foquz-dnd-cover__text {
      margin-top: 2px;
    }
  }

  &.dense {
    .foquz-dnd-cover {
      &__icon {
        svg {
          width: 40px;
          height: auto;
        }
      }
      &__content {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__text {
        margin-top: 0;
        margin-left: 12px;
        text-align: left;
      }
    }
  }
}

.foquz-dnd {
  position: relative;
  &.drag:not(.disable) {
    .foquz-dnd-cover {
      display: block;
      z-index: 100;
    }
  }
}
