@import 'Style/colors';

.channel-icon {
  display: inline-block;
  padding-right: 22px;
  position: relative;
  font-size: 0;

  .svg-icon--channel {
    width: 20px;
    height: 20px;
    &-email {
      width: 20px;
      height: 20px;
    }
    &-sms {
      width: 21px;
      height: 21px;
    }
    &-viber {
      width: 20px;
      height: 20px;
    }
    &-telegram {
      width: 19px;
      height: 16px;
    }
    &-push {
      width: 18px;
      height: 19px;
    }
  }

  &__repeats {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: @f-color-primary;
    border-radius: 10px;
    font-weight: 900;
    font-size: 11px;
    color: #ffffff;
    line-height: 1;

    position: absolute;
    top: -10px;
    right: 0;
  }
}
