@import 'Style/typography';
@import 'Style/colors';
@import 'Style/breakpoints';

.fc-stats-item {
  line-height: 1;
  display: block;
  padding: 15px 0 15px var(--fc-stats-space, 20);


  &--separator {
    border-right: 1px solid @f-color-border;
    padding-right: var(--fc-stats-space, 20);
  }

  &__value {
    font-size: 19px;
    line-height: 19px;
    font-weight: 700;
    display: flex;
  }

  &__total {
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    align-self: flex-end;
    display: flex;
    align-items: center;
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
  }

  &__label {
    font-size: @f-fs-1;
    font-weight: 500;
    color: @f-color-service;
    margin-top: 4px;
  }

  &__text + .fc-stats-item__label {
    margin-top: 7px;
  }

  .only-mobile({
    .fc-stats-container--adaptive &,
    &--adaptive {
      border-right: none;
      border-bottom: 1px solid @f-color-border;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px;

      .fc-stats-item__value {
        font-size: 19px;
      }

      .fc-stats-item__label {
        margin-top: 0;
        max-width: none!important;
      }
    }

  });

  &--primary {
    .fc-stats-item__value {
      color: @f-color-primary;
    }
  }

  &--mint {
    .fc-stats-item__value {
      color: @f-color-mint;
    }
  }

  &--gold {
    .fc-stats-item__value {
      color: @f-color-gold;
    }
  }

  &--violet {
    .fc-stats-item__value {
      color: @f-color-violet;
    }
  }

  &--blue {
    .fc-stats-item__value {
      color: @f-color-blue;
    }
  }

  &--red {
    .fc-stats-item__value {
      color: @f-color-danger;
    }
  }
}
