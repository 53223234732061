@keyframes fc-spinner-rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.fc-spinner {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &-rotator {
    position: relative;

    animation-name: fc-spinner-rotation;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
  }

  &--points {
    .fc-spinner-rotator {
      animation-duration: 2000ms;
      animation-timing-function: steps(8);

      width: 40px;
      height: 40px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &.fc-spinner--sm {
      .fc-spinner-rotator {
        width: 22px;
        height: 22px;
      }
    }
  }

  &--gradient {
    .fc-spinner-rotator {
      width: 32px;
      height: 32px;
      animation-timing-function: linear;
    }
  }
}
