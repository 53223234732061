:root {
  --foquz-sidesheet-margin: 0; //
  --foquz-sidesheet-offset: 230px;
  --foquz-sidesheet-width: 100%;
  --foquz-sidesheet-shift: 55px;
  --foquz-sidesheet-remove-button-top: 12px;
  --foquz-sidesheet-remove-all-button-top: 12px;
  --foquz-sidesheet-remove-all-button-left: var(--foquz-sidesheet-offset);
}

@media screen and (min-width: 1600px) {
  :root {
    --foquz-sidesheet-margin: var(--foquz-content-offset);
    --foquz-sidesheet-width: calc(1600px + var(--foquz-content-offset));
    --foquz-sidesheet-remove-all-button-left: calc(var(--foquz-sidesheet-margin) +var(--foquz-sidesheet-offset));
  }
}

@media screen and (max-width: 1024px) {
  :root {
    --foquz-sidesheet-offset: var(--foquz-sidesheet-shift);
    --foquz-sidesheet-remove-all-button-top: 55px;
    --foquz-sidesheet-remove-all-button-left: calc(2 * var(--foquz-sidesheet-shift) - 11px);
  }
}
