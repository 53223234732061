.f-btn-prepend {
  margin-right: 10px;
  margin-left: -3px;
  font-size: 0;

  .f-btn-text & {
    margin-left: 0;
  }

  svg {
    max-width: 16px;
    max-height: 16px;
  }

  &.f-icon {
    width: 16px;
    height: 16px;
    vertical-align: top;
    font-size: 0;

    &.f-icon--check {
      svg {
        width: 16px;
        height: 16px;
      }
    }

    &.f-icon--excel {
      margin-top: 1px;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    &.f-icon--print {
     width: 17px;
     height: 17px;
      svg {
        width: 17px;
        min-width: 17px;
        height: 17px;
        min-height: 17px;
      }
    }
  }

  .f-icon {
    width: 16px;
    height: 16px;
    vertical-align: top;
    font-size: 0;

    &--check {
      svg {
        width: 16px;
        height: 16px;
      }
    }

    &--excel {
      margin-top: 1px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .svg-icon {
    &--clip {
      width: 20px;
      height: 20px;
    }
  }
}

.f-btn-append {
  margin-left: 10px;
  font-size: 0;

  .f-icon,
  &.f-icon {
    vertical-align: top;
    font-size: 0;
  }
}

.f-btn-prepend-section {
  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 36px;
  height: calc(100% + 4px);
  margin-left: -20px;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-right: 20px;

  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.f-btn-append-section {
  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 36px;
  height: calc(100% + 4px);
  margin-right: -20px;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-left: 20px;

  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
