@import "Style/colors";

.fc-date-picker {
  display: block;
  width: 216px;

  &--inline {
    display: inline-block;
    width: auto;
  }
}

.fc-date-picker-popper {
  .fc-popper__container {
    padding: 20px;
  }

  &[data-view="modal"] {
    .fc-month-calendar {
      width: 100%;
      text-align: center;
    }
  }

  .fc-month-calendar__month-select,
  .fc-month-calendar__year-select {
    .fc-popper__container {
      padding: 10px 0;
    }
  }
}
