.foquz-media-preview {
  width: 94px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;


  &.sm {
    width: 45px;
  }

  &.lg {
    width: 105px;
  }

  &__container {
    width: 100%;
    padding-top: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .video-icon {
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  &__description {
    margin-top: 5px;
    font-size: 12px !important;
    line-height: 14px; 
    width: 100%;
  }
}
