
// old
.switch.form-control {
  padding: 0;
  border: none;
}

.switch-form-group {
  display: flex;
  align-items: center;

  .form-label {
    margin-bottom: 0;
    margin-left: 14px;

    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #73808d;

    &_checked {
      color: #2e2f31;
    }
  }

  &--sm {
    .form-label {
      margin-left: 10px;
    }
  }

  &--lg {
    .form-label {
      font-size: 16px;
      font-weight: bold;

      &_checked {
        color: #2e2f31;
      }
    }
  }
}


.foquz-switch {
  display: block;
  margin-bottom: 24px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px!important;
  flex-shrink: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .switch__slider {
    background-color: #3f65f1;
  }

  input:checked + .switch__slider:before {
    transform: translateX(24px);
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #dadfe3;
    border-radius: 50px;

    transition: 0.4s;

    &:before {
      content: '';

      position: absolute;
      height: 22px;
      width: 22px;
      left: 2px;
      bottom: 2px;

      background-color: #fff;
      border-radius: 50%;

      transition: 0.4s;
    }
  }

  input[disabled] + .switch__slider {
    opacity: 0.5;
    cursor: default;
  }

  &--sm {
    width: 28px;
    height: 16px !important;

    input:checked + .switch__slider:before {
      transform: translateX(12px);
    }
  }

  &--sm & {
    &__slider {
      &:before {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.switch-form-group {
  .form-label {
    line-height: 1.2;
  }
  .form-label_disabled {
    cursor: default;
  }
}
