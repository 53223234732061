.icon-viewport-mode-sizes() {
  phone: 13, 20;
  tablet: 17, 20;
  desktop: 22, 18;
}


.f-icon-viewport-mode {
  cursor: pointer;

  &:hover {
    color: @f-color-primary;
  }

  &.active {
    color: @f-color-primary;

    &:hover {
      color: @f-color-primary--hover;
    }
  }

  each(.icon-viewport-mode-sizes(), .(@v, @k) {
    &--@{k} {

      svg {
        width: 1px * extract(@v, 1);
        height: 1px * extract(@v, 2);
      }
    }
  });
}
