@import 'Style/colors';

.fc-textarea {
  display: block;
  font-size: 16px;

  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding: 0;
    min-height: 48px;
  }

  &__field,
  &__count,
  &__valid {
    position: relative;
    z-index: 1;
  }

  &__field {
    border: none;
    background-color: transparent;
    min-height: 72px;
    padding: 14px 15px;
    overflow: hidden;
    width: 100%;
    font-size: 16px;
    line-height: 1.3;
    resize: none!important;

    &:focus {
      outline: none;
      border: none;
    }

    &::placeholder {
      color: @f-color-service;
    }

  }


  &__count {
    position: absolute;
    z-index: 1;
    bottom: 8px;
    right: 12px;
    font-size: 11px;
    color: @f-color-text-inactive;
  }



  &__view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    border: 1px solid @f-color-border--dark;
    border-radius: inherit;
    pointer-events: none;
    font: inherit;
    color: @f-color-text;
    z-index: 0;
  }

  &__field:focus ~ .fc-textarea__view {
    border-color: @f-color-primary;
    box-shadow: 0 0 5px rgba(63, 101, 241, 0.5);
  }

  &--invalid {
    .fc-textarea__view {
      border-color: @f-color-danger;
      box-shadow: none;
    }
  }

  .fc-textarea__valid {
    display: none;
    position: absolute;
    right: 12px;
    bottom: 8px;
  }

  &--valid {
    .fc-textarea__view {
      border-color: @f-color-success;
      box-shadow: none;
    }
    .fc-textarea__valid {
      display: block;
    }
    .fc-textarea__count {
      display: none;
    }
  }

  &--disabled {
    .fc-textarea__field {
      opacity: 0.5;
    }

    .fc-textarea__view {
      background-color: #f2f5f6;
    }
  }

  &-reader {
    font-size: 14px;
  }
}
