.time-picker {
  &.dropdown-menu {
    min-height: 200px;


  }

  .dropdown-item {
    text-align: center;
  }

  // .ps {
  //   &__rail-y {
  //     right: 5px;
  //   }
  // }
}
