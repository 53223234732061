.fc-auto-input {
  display: inline-block;

  &__wrapper {
    display: flex;
    position: relative;
  }

  input {
    border: none;
    outline: none;
    min-width: 5px;
    max-width: 100%;
  }

  &__icons {
    display: flex;
    align-items: center;
  }

  &__append-icon {
    margin-left: 10px;
    cursor: pointer;
    transform: translateY(-2px);
  }
}
