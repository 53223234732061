.foquz-modals-container {
  &__close-all {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1051;

    display: flex;
    justify-content: flex-end;

    width: calc(100vw - 1365px);
    min-width: 135px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 35px;
      margin-top: 10px;
      padding: 0 15px;

      color: #73808d;
      background: transparent;

      border: 2px solid #73808d;
      box-shadow: none;
      outline: none;

      border-radius: 50px;

      font-size: 14px;
      font-weight: 500;

      cursor: pointer;
    }
  }
}
