.icon-social-sizes() {
  // соц. сети
  fb: 13, 20;
  vk: 25, 16;
  inst: 20, 21;

}
.f-icon-social {
  width: 25px;
  height: 25px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  each(.icon-social-sizes(), .(@v, @k) {
    &--@{k} {
      width: 1px * extract(@v, 1);

      svg {
        width: 1px * extract(@v, 1);
        height: 1px * extract(@v, 2);
      }
    }
  });
}
