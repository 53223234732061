@import 'Style/typography';
@import 'Style/colors';
@import 'Style/breakpoints';

.fc-stats-container {
  border-top: 1px solid @f-color-border;
  border-bottom: 1px solid @f-color-border;
  display: block;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-1 * var(--fc-stats-space));
  }

  .fc-stats-item {
    border-top: 1px solid @f-color-border;
    border-bottom: 1px solid @f-color-border;
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .only-mobile({
    display: block;
  });
}
