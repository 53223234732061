@import 'Style/colors';

.rating-point {
  display: block;
  position: relative;

  width: 28px;
  height: 28px;



  &__point {
    width: 100%;
    height: 100%;
    border: 2px solid white;
    background: #eee;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 13px;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;

    &[data-point='1'] {
      background: @rating-1-color;
    }
    &[data-point='2'] {
      background: @rating-2-color;
    }
    &[data-point='3'] {
      background: @rating-3-color;
    }
    &[data-point='4'] {
      background: @rating-4-color;
    }
    &[data-point='5'] {
      background: @rating-5-color;
    }

    &[data-img] {
      background: transparent;

      
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .svg-icon {
      width: 12px;
      height: 10px;

      &[class *= 'heart' ] {
        margin-top: 2px;
      }
      &[class *= 'face' ] {
        width: 16px;
        height: 11px;
      }
    }
  }

  &__comment {
    position: absolute;
    top: -4px;
    right: 0;
  }

  &__skipped {
    position: absolute;
    top: -4px;
    right: 0;

    & + .rating-point__comment {
      right: 8px;
    }
  }

  & + .rating-point {
    margin-left: -4px;
  }

  &.lg {
    width: 34px;
    height: 34px;

    .svg-icon {
      width: 16px;
      height: 16px;

      &[class*='face'] {
        height: 12px;
      }
    }
  }
}
