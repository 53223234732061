.stickyFooterGradient() {
  &:before {
    pointer-events: none;
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    height: 22px;
    background: linear-gradient(
      180deg,
      rgba(115, 128, 141, 0) 0%,
      rgba(115, 128, 141, 0.15) 100%
    );
  }
}

.sticky-footer {
  position: sticky !important;
  bottom: -1px;
  z-index: 8;

  &.stucky {
    .stickyFooterGradient();
  }
}

.fixed-footer {
  z-index: 8;
  &.fixed {
    .stickyFooterGradient();
  }
}
