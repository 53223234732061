@import "Style/colors";
.fc-folders-tree {
  &__item {
    margin-right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 30px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;

    .svg-icon {
      margin-right: 12px;
      flex-shrink: 0;
      color: @f-color-service;
      align-self: flex-start;
      transform: translateY(2px);
    }

    &.initial {
      color: @f-color-service;
    }

    &:not(.disabled):hover {
      background: #f1f4ff;
    }

    &.selected {
      color: @f-color-primary;
      background: #f1f4ff;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
