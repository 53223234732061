@import 'Style/breakpoints';

.mobile-modal-page() {
  .modal-dialog {
    padding-left: 0;
  }
  .foquz-modal-page__close {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-top: 0;

    width: 48px;
    height: 48px;

    background-color: #DFE3E5;
    border-radius: 0;
    border: 0;

    font-weight: 300;
  }
  .foquz-modal-page__header {
    padding-left: 20px;
    padding-right: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.modal.foquz-modal-page {

  &:not(.foquz-modal-page--sm) {
    .mobile-and-tablet({
      .mobile-modal-page();
    });
  }

  &.foquz-modal-page--sm {
    .only-mobile({
      .mobile-modal-page();
    })
  }

  .only-mobile({
    .foquz-modal-page__close {

      width: 60px;
      height: 60px;
    }

    .foquz-modal-page__header {
      padding-left: 15px;
      padding-right: 80px;
      padding-top: 20px;
    }
    .foquz-modal-page__title {
      font-size: 16px;
      font-weight: 500;
    }
  })
}
