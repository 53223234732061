@import 'Style/colors';

.fc-name-form {
  display: block;
  max-width: 100%;
  overflow: hidden;

  &__view {
    display: flex;
    align-items: flex-start;

    .fc-name-form__name {
      font-size: 22px;
      font-weight: 900;
      padding-bottom: 4px;
      margin-right: 12px;
      line-height: 1.3;
      border-bottom: 1px solid transparent;
    }

    .fc-name-form__edit {
      margin-top: 5px;
    }
  }

  &__form {
    display: flex;

    .fc-name-form__field {
      &, &:hover, &:focus {
        outline: none;
        background-color: transparent;
      }

      font: inherit;
      font-size: 22px;
      font-weight: 900;
      line-height: 1.3;

      padding: 0;
      padding-bottom: 4px;

      border: none;
      border-bottom: 1px solid @f-color-border--dark;

      margin-right: 12px;
    }

    .fc-name-form__cancel {
      margin-right: 12px;
    }
  }
}
