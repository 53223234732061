.fc-scroll {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;

  &__wrapper {
    display: block;
    flex-grow: 1;
    height: 100%;
    max-height: 100%;
    overflow: hidden !important;

    &.os-host {
      overflow: hidden !important;
    }
  }

  .os-scrollbar-vertical {
    width: 8px;
  }

  .os-scrollbar-horizontal {
    height: 8px;
  }

  .os-scrollbar-track {
    background: #e8edee !important;
  }

  .os-scrollbar-handle {
    background: #8e99a3;
  }

  .fc-scroll-indicator {
    &__horizontal,
    &__vertical {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }

    &__horizontal {
      &:before,
      &:after {
        content: "";
        width: 20px;
        position: absolute;
        top: 0;
        bottom: 0;
        display: none;
      }

      &:before {
        left: 0;
        background: linear-gradient(
          to right,
          var(--fc-scroll-gradient, white),
          transparent
        );
      }

      &:after {
        right: 0;
        background: linear-gradient(
          to left,
          var(--fc-scroll-gradient, white),
          transparent
        );
      }
    }

    &__vertical {
      &:before,
      &:after {
        content: "";
        height: 20px;
        position: absolute;
        left: 0;
        right: 0;
        display: none;
      }

      &:before {
        top: 0;
        background: linear-gradient(
          to bottom,
          var(--fc-scroll-gradient, white),
          transparent
        );
      }

      &:after {
        bottom: 0;
        background: linear-gradient(
          to top,
          var(--fc-scroll-gradient, white),
          transparent
        );
      }
    }
  }

  &--indicator {
    &.fc-scroll--has-x {
      .fc-scroll-indicator__horizontal {
        display: block;

        &:before,
        &:after {
          display: block;
        }
      }

      &.fc-scroll--start-x {
        .fc-scroll-indicator__horizontal:before {
          display: none;
        }
      }

      &.fc-scroll--end-x {
        .fc-scroll-indicator__horizontal:after {
          display: none;
        }
      }
    }

    &.fc-scroll--has-y {
      .fc-scroll-indicator__vertical {
        display: block;

        &:before,
        &:after {
          display: block;
        }
      }

      &.fc-scroll--start-y {
        .fc-scroll-indicator__vertical:before {
          display: none;
        }
      }

      &.fc-scroll--end-y {
        .fc-scroll-indicator__vertical:after {
          display: none;
        }
      }
    }
  }

  &--touch {
    .os-scrollbar {
      visibility: hidden;
    }
  }
}
