@import "Style/colors";
@import "Style/breakpoints";

.fc-number-range {
  display: block;
  font-size: 16px;
  width: 100%;

  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__divider {
    vertical-align: middle;
    line-height: 1;
    flex-shrink: 0;
    width: 30px;
    text-align: center;
  }

  &__from,
  &__to {
    width: calc(50% - 15px);
  }
}
