.f-btn-group {
  display: flex;

  .f-btn {
    margin-left: 0;
    margin-right: 0;
    background-color: #f1f5f6;
    color: @f-color-service;
    border: 1px solid #cfd8dc;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
    padding-left: 20px;
    padding-right: 20px;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0.5px;
      margin-left: -1px;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0.5px;
    }

    &.active {
      background-color: white;
      color: @f-color-text;
    }

    &[disabled],
    &.disabled {
      opacity: 1;
      color: #A6B1BC;
      cursor: not-allowed;
    }
  }
}
